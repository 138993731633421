import api from '../api';
import Driver from '@/interfaces/Driver';
import { DriverOfferFormSend } from '@/interfaces/Driver/offer';

export const driverOffersApi = api.injectEndpoints({
  endpoints: build => ({
    updateDriverOffers: build.mutation<Driver, [string, DriverOfferFormSend]>({
      query: ([idDriver, data]) => ({
        url: `/drivers/${idDriver}/offers`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, _error, data) => [
        { type: 'Offers', id: 'LIST' },
      ],
    }),
  }),
  overrideExisting: true,
});

export const { useUpdateDriverOffersMutation } = driverOffersApi;
