import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorComponent from '@/components/common/ErrorComponent';
import { Col, Row } from 'react-bootstrap';
import { UUID_REGEX } from '@/constants/regexs';
import WeeklyChallengeDetailsCard from '@/components/cards/weeklyChallenges/WeeklyChallengeDetailsCard';
import { IWeeklyChallenge } from '@/interfaces/IWeeklyChallenges';
import WeeklyChallengeHeader from '@/components/card-headers/weeklyChallenge/WeeklyChallengeHeader';
import WeeklyChallengesDriversTable from '@/components/cards/weeklyChallenges/WeeklyChallengesDriversTable';

const WeeklyChallengeView: FC = () => {
  const { id } = useParams<{ id: string }>();
  const [weeklyChallenge, setWeeklyChallenge] = useState<IWeeklyChallenge>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      {weeklyChallenge && <WeeklyChallengeHeader id={id} />}
      <Row className="g-3 mb-3">
        <Col xxl={12}>
          <WeeklyChallengeDetailsCard
            id={id}
            card
            callBack={setWeeklyChallenge}
          />
        </Col>
        <Col xxl={12} className="mt-4">
          <WeeklyChallengesDriversTable weeklyChallengeId={id} />
        </Col>
      </Row>
    </div>
  );
};

export default WeeklyChallengeView;
