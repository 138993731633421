import IconButton from '@/components/common/IconButton';
import { ITableHeaderComponentProps } from '@/interfaces/Table';
import {
  faFileExport,
  faFilter,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import { Button, Col, FormControl, InputGroup, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';

const AllRideOffersHeader: FC<ITableHeaderComponentProps> = ({
  onQueryChange,
  onShowFilters,
}) => {
  const userConfig = useSelector(selectCurrentUserConfig);

  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Offers</h6>
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        {!!onShowFilters && (
          <>
            <IconButton
              variant="nolina-default"
              size="sm"
              icon={faFilter}
              transform="shrink-4"
              iconAlign="middle"
              onClick={onShowFilters}
              className="d-xl-none"
            >
              <span className="d-none d-sm-inline-block ms-1">Filtres</span>
            </IconButton>
            <div
              className="bg-300 mx-3 d-none d-lg-block d-xl-none"
              style={{ width: '1px', height: '29px' }}
            ></div>
          </>
        )}
      </div>
    </div>
  );
};

export default AllRideOffersHeader;
