import { CominConfigurationState } from '@/interfaces/CominConfiguration';
import {
  useCominConfigurationQuery,
  useUpdateCominConfigurationMutation,
} from '@/services/cominConfiguration';
import { useEffect, useReducer } from 'react';
import { Button, Card, Form } from 'react-bootstrap';

const SettingsPage = () => {
  const { data, refetch } = useCominConfigurationQuery();
  const [update] = useUpdateCominConfigurationMutation();
  const [input, dispatch] = useReducer(
    (
      state: CominConfigurationState,
      action: { type: string; value: string | boolean }
    ): CominConfigurationState => {
      switch (action.type) {
        case 'driver_premium':
          return {
            ...state,
            driver_premium: action.value as string,
          };
        case 'is_active':
          return {
            ...state,
            is_active: action.value as boolean,
          };
        case 'user_app_version':
          return {
            ...state,
            user_app_version: action.value as string,
          };
        case 'driver_app_version':
          return {
            ...state,
            driver_app_version: action.value as string,
          };
        default:
          return state;
      }
    },
    {
      driver_premium: '0',
      is_active: true,
      user_app_version: '0.0.0',
      driver_app_version: '0.0.0',
    } as CominConfigurationState
  );

  const driverPremiumHasChanges =
    input.driver_premium !==
      data?.find(d => d.configurationKey === 'driver_premium')
        ?.configurationValue ||
    input.is_active !==
      data?.find(d => d.configurationKey === 'driver_premium')?.isActive;
  const userAppHasChanges =
    input.user_app_version !==
    data?.find(d => d.configurationKey === 'user_app_version')
      ?.configurationValue;
  const driverAppHasChanges =
    input.driver_app_version !==
    data?.find(d => d.configurationKey === 'driver_app_version')
      ?.configurationValue;
  const onChange = (
    key: keyof CominConfigurationState,
    value: string | boolean
  ) => {
    dispatch({ type: key, value });
  };

  useEffect(() => {
    if (data) {
      data.forEach(config => {
        dispatch({
          type: config.configurationKey,
          value: config.configurationValue,
        });

        if (config.configurationKey === 'driver_premium') {
          dispatch({
            type: 'is_active',
            value: config.isActive,
          });
        }
      });
    }
  }, [data]);

  const onSubmit = async (key: keyof CominConfigurationState) => {
    await update({
      configurationKey: key,
      configurationValue: input[key] as string,
      isActive: input.is_active,
    });

    refetch();
  };

  return (
    <div>
      <Card className="sticky top-0">
        <Card.Header>
          <Card.Title>Settings</Card.Title>
        </Card.Header>
      </Card>

      <Card className="mt-5">
        <Card.Header>
          <Card.Title>Driver Premium</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group controlId="driver_premium">
              <Form.Label>Price (in Euro)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Price"
                value={input.driver_premium}
                onChange={e => onChange('driver_premium', e.target.value)}
              />
              <Form.Text className="text-muted">
                The price is divided by 100, so if you want to set the price to
                1.50€, you should enter 150
              </Form.Text>
            </Form.Group>

            <Form.Group className="mt-4" controlId="driver_premium_is_active">
              <Form.Label>Active</Form.Label>
              <Form.Check
                type="switch"
                label="Add the above value to driver price if the ride request is acheiving Premium phase"
                checked={input.is_active}
                onChange={e => onChange('is_active', e.target.checked)}
              />
            </Form.Group>
          </Form>

          <Button
            className="mt-4"
            variant="primary"
            disabled={!driverPremiumHasChanges}
            onClick={() => onSubmit('driver_premium')}
          >
            Save Changes
          </Button>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Header>
          <Card.Title>User App version</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group controlId="driver_premium">
              <Form.Label>Version</Form.Label>
              <Form.Control
                type="text"
                placeholder="0.0.0"
                value={input.user_app_version}
                onChange={e => onChange('user_app_version', e.target.value)}
              />
            </Form.Group>
          </Form>

          <Button
            className="mt-4"
            variant="primary"
            disabled={!userAppHasChanges}
            onClick={() => onSubmit('user_app_version')}
          >
            Force update
          </Button>
        </Card.Body>
      </Card>

      <Card className="mt-5">
        <Card.Header>
          <Card.Title>Driver App version</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form>
            <Form.Group controlId="driver_premium">
              <Form.Label>Version</Form.Label>
              <Form.Control
                type="text"
                placeholder="0.0.0"
                value={input.driver_app_version}
                onChange={e => onChange('driver_app_version', e.target.value)}
              />
            </Form.Group>
          </Form>

          <Button
            className="mt-4"
            variant={'primary'}
            disabled={!driverAppHasChanges}
            onClick={() => onSubmit('driver_app_version')}
          >
            Force update
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SettingsPage;
