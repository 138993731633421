import Modal from '@/components/common/Modal';
import { IModalMethods } from '@/interfaces/Modal';

import {
  ElementRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react';
import { Card, Col, Row } from 'react-bootstrap';

type UserListChooseModalProps = {
  refUserListForm: React.RefObject<IModalMethods>;
  refUserListIds: React.RefObject<IModalMethods>;
};

const UserListChooseModal: ForwardRefRenderFunction<
  IModalMethods,
  UserListChooseModalProps
> = ({ refUserListForm, refUserListIds }, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  return (
    <Modal ref={ModalRef} title="Choose user list criteria">
      <Row>
        <Col>
          <Card className="h-100">
            <Card.Body
              className=" d-flex align-items-center justify-content-center rounded"
              onClick={() => {
                refUserListForm.current?.showOrHiddenModal();
                showOrHiddenModal();
              }}
              style={{ height: 80, cursor: 'pointer' }}
            >
              Single user form
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="h-100">
            <Card.Body
              onClick={() => {
                refUserListIds.current?.showOrHiddenModal();
                showOrHiddenModal();
              }}
              className="p-2 d-flex align-items-center justify-content-center rounded"
              style={{ height: 80, cursor: 'pointer' }}
            >
              Users' ids
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(UserListChooseModal);
