import BookingCardHeader from '@/components/card-headers/booking/BookingHeader';
import BookingFormCard from '@/components/cards/booking/BookingFormCard';
import ErrorComponent from '@/components/common/ErrorComponent';
import { UUID_REGEX } from '@/constants/regexs';
import { useParams } from 'react-router-dom';

const EditBookingView = () => {
  const { id } = useParams<{ id: string }>();

  if (!id || !UUID_REGEX.test(id)) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <div>
      <BookingCardHeader id={id} isEdit/>
      <BookingFormCard bookingId={id}/>
    </div>
  );
};

export default EditBookingView;
