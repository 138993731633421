import Driver, {
  DriverExperienceYears,
  DriverPrimaryShift,
  IDriverFormSend,
  IDriverFormSendCSV,
  WeeklyDrivingHours,
} from '@/interfaces/Driver';
import { DriverBlockedStatus } from '@/interfaces/DriverBlocked';
import { DriverServiceStatus } from '@/interfaces/DriverService';
import { DriverRideRequestStatus } from '@/interfaces/Ride/DriverNotification';
import { DynamicData } from './TableHelper';

export const getColorFromStatus = (status?: DriverBlockedStatus) => {
  if (status === 'blocked') return 'danger';
  else if (status === 'reviewing') return 'info';
  else if (status === 'soon') return 'warning';
  else return 'success';
};

export const getStatusName = (status?: DriverBlockedStatus) => {
  if (status === 'blocked') return 'Blocked';
  else if (status === 'reviewing') return 'Reviewing';
  else if (status === 'soon') return 'Will be blocked soon';
  else return 'Active';
};

export const getColorFromDriverNotificationStatusColor = (
  status: DriverRideRequestStatus
) => {
  if (status === 'requested') return 'light';
  else if (status === 'accepted') return 'success';
  else if (status === 'rejected') return 'warning';
  else if (status === 'user_cancelled') return 'pink';
  else if (status === 'cancelled') return 'danger';
  else if (status === 'timouted') return 'purple';
  else return 'orange';
};

export const getDriverNotificationStatusName = (
  status: DriverRideRequestStatus
) => {
  if (status === 'requested') return 'Requested';
  else if (status === 'accepted') return 'Accepted';
  else if (status === 'rejected') return 'Rejected';
  else if (status === 'user_cancelled') return 'User Cancelled';
  else if (status === 'cancelled') return 'Cancelled';
  else if (status === 'timouted') return 'Time Out';
  else return 'Other Driver Accepted';
};

export const getColorFromService = (status?: DriverServiceStatus) => {
  if (status === 'completed') return 'grey';
  else if (status === 'started') return 'success';
  else return 'warning';
};

export const getNameFromService = (status?: DriverServiceStatus) => {
  if (status === 'completed') return 'OFF';
  else if (status === 'started') return 'ON';
  else return 'None';
};

export const getReelNameFromDriverExperienceYears = (
  exp?: DriverExperienceYears
) => {
  switch (exp) {
    case 'LessThanOneYear':
      return 'Less Than 1 Year';
    case 'OneToTwoYears':
      return 'Since 1 to 2 Years';
    case 'ThreeToFiveYears':
      return 'Since 3 to 5 Years';
    case 'SixToTenYears':
      return 'Since 6 to 10 Years';
    case 'MoreThanTenYears':
      return 'More Than 10 Years';
    default:
      return 'None';
  }
};

export const getBlockedBy_BasedOnStatus = (driver: Driver) => {
  if (driver.blockedProfile) {
    if (
      ['blocked', 'unblocked', 'reviewing', 'soon'].indexOf(
        driver.blockedProfile.status
      ) > -1
    ) {
      return driver.blockedProfile.blockedBy;
    } else {
      return driver.blockedProfile.unblockedBy;
    }
  }
  return null;
};

export const getReelNameFromWeeklyDrivingHours = (type: WeeklyDrivingHours) => {
  switch (type) {
    case 'LessThan10Hours':
      return 'Less Than 10 Hours';
    case '10To20Hours':
      return '10 to 20 Hours';
    case '21To30Hours':
      return '21 to 30 Hours';
    case '31To40Hours':
      return '31 to 40 Hours';
    case 'MoreThan40Hours':
      return 'More Than 40 Hours';
    default:
      return 'None';
  }
};

export const getReelNameFromWeeklyDrivingHoursForCSV = (type: string) => {
  switch (type) {
    case 'Moins de 10 heures':
      return 'LessThan10Hours';
    case '10-20 heures':
      return '10To20Hours';
    case '21-30 heures':
      return '21To30Hours';
    case '31-40 heures':
      return '31To40Hours';
    case 'Plus de 40 heures':
      return 'MoreThan40Hours';
    default:
      return 'LessThan10Hours';
  }
};

export const getReelNameFromDriverPrimaryShift = (type: DriverPrimaryShift) => {
  switch (type) {
    case 'day':
      return 'Day';
    case 'night':
      return 'Night';
    case 'both':
      return 'Both';
    default:
      return 'None';
  }
};

export const getReelNameFromDriverPrimaryShiftForCSV = (type: string) => {
  switch (type) {
    case 'Jour':
      return 'day';
    case 'Nuit':
      return 'night';
    case 'Les 2':
      return 'both';
    default:
      return 'both';
  }
};

export const getReelNameFromDriverExperienceYearsForCSV = (exp?: string) => {
  switch (exp) {
    case "Moins d'un an":
      return 'LessThanOneYear';
    case '1-2 ans':
      return 'OneToTwoYears';
    case '3-5 ans':
      return 'ThreeToFiveYears';
    case '6-10 ans':
      return 'SixToTenYears';
    case 'Plus de 10 ans':
      return 'MoreThanTenYears';
    default:
      return 'ThreeToFiveYears';
  }
};

export const formCsvToDriver = (d: DynamicData) => {
  return {
    primaryShift: d.Rythme as DriverPrimaryShift,
    driverExperienceYears: d.Experience as string,
    weeklyDrivingHours: getReelNameFromWeeklyDrivingHoursForCSV(
      d.Hours as string
    ),
    referralCode: d.Referral_Code as string,
    isCompanyRattachement: d.Rattachement === 'Oui',
    commissionRate: 10,
  } as IDriverFormSendCSV;
};
