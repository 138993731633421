import CardHeader from '@/components/card-headers/CardHeader';
import useLoadOptions from '@/hooks/useLoadOptions';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import classNames from 'classnames';
import { ToastContentProps, toast } from 'react-toastify';
import { INVALID_FORM_MESSAGE } from '@/constants/validatorMessages';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Select, {
  AsyncSelectComponent,
  CustomGoogleMenu,
} from '@/components/common/Select';
import { IBookingEditForm, STATUSES } from '@/interfaces/Booking';
import { useEffect } from 'react';
import {
  useGetBookingByIdQuery,
  useUpdateBookingMutation,
} from '@/services/bookings';
import { DateTime } from 'luxon';

interface BookingFormCardProps {
  bookingId: string;
}

const BookingFormCard = ({ bookingId }: BookingFormCardProps) => {
  const { loadPlacesAutocomplete } = useLoadOptions();
  const {
    handleSubmit,
    control,
    reset,
    register,
    formState: { errors },
  } = useForm<IBookingEditForm>({
    defaultValues: {},
  });
  const [mutateAsync] = useUpdateBookingMutation();
  const { data } = useGetBookingByIdQuery(bookingId);

  const onSubmit: SubmitHandler<IBookingEditForm> = data => {
    toast.promise(
      mutateAsync([
        bookingId,
        {
          originId: data.origin.placeId,
          destinationId: data.destination.placeId,
          startDate: data.startDate,
          startTime: data.time,
          passengerFirstname: data.passengerFirstname,
          passengerLastname: data.passengerLastname,
          passengerEmail: data.passengerEmail,
          passengerPhone: data.passengerPhone,
          comments: data.comments,
          flightNumber: data.flightNumber,
          language: data.language,
          status: data?.status?.value,
        },
      ]).unwrap(),
      {
        pending: 'Enregistrement de la demande en cours...',
        success: {
          render() {
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                La demande à bien été enregistrée 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IBookingEditForm> = () =>
    toast.error(INVALID_FORM_MESSAGE);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        origin: {
          name: data.originAddress,
          placeId: data.originPlaceId,
        },
        destination: {
          name: data.destinationAddress,
          placeId: data.destinationPlaceId,
        },
        startDate: data.startDate.split('T')[0],
        time: DateTime.fromISO(data.time).toFormat('HH:mm'),
        status: STATUSES.find(
          status => status.value === data.status.toUpperCase()
        ),
      });
    }
  }, [data, reset]);

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <CardHeader title="Simuler des offres" />
        <Card.Body>
          <Row className="gx-3">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Origin <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="origin"
                  rules={{
                    required: 'Origin address is mandatory',
                  }}
                  render={({ field }) => (
                    <AsyncSelectComponent
                      loadOptions={loadPlacesAutocomplete}
                      value={field.value}
                      closeMenuOnSelect
                      isClearable
                      onChange={field.onChange}
                      components={{
                        Menu: CustomGoogleMenu,
                      }}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.place_id}
                    />
                  )}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={classNames({
                    'd-block': errors.origin,
                  })}
                >
                  {errors.origin?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Destination <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="destination"
                  rules={{
                    required: 'Destination address is mandatory',
                  }}
                  render={({ field }) => (
                    <AsyncSelectComponent
                      loadOptions={loadPlacesAutocomplete}
                      value={field.value}
                      closeMenuOnSelect
                      isClearable
                      onChange={field.onChange}
                      components={{
                        Menu: CustomGoogleMenu,
                      }}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.place_id}
                    />
                  )}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={classNames({
                    'd-block': errors.destination,
                  })}
                >
                  {errors.destination?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Start Date <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  type="date"
                  {...register('startDate')}
                  isInvalid={!!errors.startDate}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.startDate?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Start Time <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  type="time"
                  {...register('time')}
                  isInvalid={!!errors.time}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.time?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Passenger Firstname <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('passengerFirstname', {
                    required: {
                      value: true,
                      message: 'INVALID_FIRSTNAME_MESSAGE',
                    },
                    minLength: {
                      value: 2,
                      message: 'INVALID_MINIMUM_CARACTERE_MESSAGE',
                    },
                    maxLength: {
                      value: 255,
                      message: 'INVALID_MAXIMUM_CARACTERE_MESSAGE',
                    },
                  })}
                  isInvalid={!!errors.passengerFirstname}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passengerFirstname?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Passenger Lastname <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('passengerLastname', {
                    required: {
                      value: true,
                      message: 'INVALID_FIRSTNAME_MESSAGE',
                    },
                    minLength: {
                      value: 2,
                      message: 'INVALID_MINIMUM_CARACTERE_MESSAGE',
                    },
                    maxLength: {
                      value: 255,
                      message: 'INVALID_MAXIMUM_CARACTERE_MESSAGE',
                    },
                  })}
                  isInvalid={!!errors.passengerLastname}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passengerLastname?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Passenger Email <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('passengerEmail', {
                    required: {
                      value: true,
                      message: 'INVALID_FIRSTNAME_MESSAGE',
                    },
                    minLength: {
                      value: 2,
                      message: 'INVALID_MINIMUM_CARACTERE_MESSAGE',
                    },
                    maxLength: {
                      value: 255,
                      message: 'INVALID_MAXIMUM_CARACTERE_MESSAGE',
                    },
                  })}
                  isInvalid={!!errors.passengerEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passengerEmail?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Passenger Phone <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  type="text"
                  {...register('passengerPhone', {
                    required: {
                      value: true,
                      message: 'INVALID_FIRSTNAME_MESSAGE',
                    },
                    minLength: {
                      value: 2,
                      message: 'INVALID_MINIMUM_CARACTERE_MESSAGE',
                    },
                    maxLength: {
                      value: 255,
                      message: 'INVALID_MAXIMUM_CARACTERE_MESSAGE',
                    },
                  })}
                  isInvalid={!!errors.passengerPhone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.passengerPhone?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Status <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <Select
                      options={STATUSES as any}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={classNames({
                    'd-block': !!errors.status,
                  })}
                >
                  {errors.status?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Flight / Train number :</Form.Label>
                <Form.Control
                  type="text"
                  {...register('flightNumber')}
                  isInvalid={!!errors.flightNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.flightNumber?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={12}>
              <Form.Group className="mb-3">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  {...register('comments')}
                  isInvalid={!!errors.comments}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.flightNumber?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          <Button type="submit" variant="primary" size="sm">
            Modifier
          </Button>
        </Card.Footer>
      </Card>
    </Form>
  );
};

export default BookingFormCard;
