import Modal from '@/components/common/Modal';
import Select from '@/components/common/Select';
import { changeNoneToNullValue } from '@/helpers/utils';
import { IModalMethods } from '@/interfaces/Modal';
import { USER_TYPES_OPTIONS } from '@/interfaces/User';
import {
  IUserListForm,
  IUserListIdsForm,
  IUserListIdsFormSend,
  REGISTRATION_FILTER_OPTIONS,
  RIDES_STATUS_OPTIONS,
} from '@/interfaces/User/UserList';
import { useCreateUserListCriterionMutation } from '@/services/userList';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import {
  ElementRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { toast, ToastContentProps } from 'react-toastify';

const UserListChooseIdsModal: ForwardRefRenderFunction<IModalMethods> = (
  _,
  ref
) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const [createUserListAsync] = useCreateUserListCriterionMutation();

  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IUserListIdsForm>();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  const onError: SubmitErrorHandler<IUserListIdsForm> = () => {};

  const onSubmit: SubmitHandler<IUserListIdsForm> = data => {
    const dataSent: IUserListIdsFormSend = {
      ...data,
      type: 'ids',
    };

    toast.promise(createUserListAsync(dataSent).unwrap(), {
      pending: 'Creating user list...',
      success: {
        render() {
          reset();
          showOrHiddenModal();
          return (
            <p style={{ marginBottom: 0, textAlign: 'center' }}>
              User list has been added 🤩
            </p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          if (data?.status === 422) {
            return 'The fields you filled in seem to be incorrect.';
          }

          return 'An error occurred.';
        },
      },
    });
  };

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  return (
    <Modal
      ref={ModalRef}
      title="Create user list"
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Col lg={6}>
        <Form.Group className="mb-3">
          <Form.Label>User List Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter user list name"
            {...register('name', { required: 'Name is required' })}
            isInvalid={!!errors.name}
          />
          <Form.Control.Feedback type="invalid">
            {errors.name?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className="mb-3">
          <Form.Label>User ids</Form.Label>
          <Form.Control
            type="text"
            as="textarea"
            placeholder="a24092c1-c6d8-4d43-ae34-515209db5373,77f6b80b-50f3-484a-8011-725c442eca24,etc..."
            {...register('ids', { 
              required: 'Ids are required',
              validate: value => {
                const ids = value.split(',');
                if (ids.some(id => !id.match(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/))) {
                  return 'Ids are not valid';
                }
                return true;
              }
            })}
            isInvalid={!!errors.ids}
          />
          <Form.Control.Feedback type="invalid">
            {errors.ids?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Modal>
  );
};

export default forwardRef(UserListChooseIdsModal);
