import api from './api';

export const bookingPriceControllerApi = api.injectEndpoints({
  endpoints: (build) => ({
    getBookingPriceControllers: build.query({
      query: (data) => ({
        url: '/v2/booking-price-controller',
        params: data,
      }),
    }),
    updateBookingPriceController: build.mutation({
      query: (body) => ({
        url: '/v2/booking-price-controller',
        method: 'PATCH',
        body,
      }),
    }),
  }),
})

export const {
  useGetBookingPriceControllersQuery,
  useUpdateBookingPriceControllerMutation,
} = bookingPriceControllerApi;