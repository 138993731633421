import { CominConfiguration } from '@/interfaces/CominConfiguration';
import api from './api';

export const cominConfigurationApi = api.injectEndpoints({
  endpoints: build => ({
    cominConfiguration: build.query<CominConfiguration[], void>({
      query: () => '/comin-configuration',
    }),
    updateCominConfiguration: build.mutation<
      CominConfiguration,
      {
        configurationKey: string;
        configurationValue: string;
        isActive: boolean;
      }
    >({
      query: ({ configurationKey, configurationValue, isActive }) => ({
        url: '/comin-configuration',
        method: 'PUT',
        body: { configurationKey, configurationValue, isActive },
      }),
    }),
  }),
});

export const {
  useCominConfigurationQuery,
  useUpdateCominConfigurationMutation,
} = cominConfigurationApi;
