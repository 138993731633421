import Avatar from '@/components/common/Avatar';
import Flex from '@/components/common/Flex';
import ImageOverview from '@/components/common/ImageOverview';
import StatisticsCard from '@/components/common/StatisticsCard';
import { IFilters } from '@/interfaces/Fetch';
import { useGetRequestOffersQuery } from '@/services/requests/offers';
import {
  faChair,
  faClock,
  faEuroSign,
  faHourglass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { FC, Fragment, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import RequestPaymentCard from './RequestPaymentCard';

const RequestOffersCard: FC<{ id: string }> = ({ id }) => {
  const [filters, setFilters] = useState<IFilters>({
    pageIndex: 0,
    pageSize: 3,
  });

  const { data, isLoading, error } = useGetRequestOffersQuery([id, filters]);
  let navigate = useNavigate();

  return (
    <>
      <Fragment>
        {data?.data && data.data.length > 0 ? (
          data?.data.map(
            (
              {
                id,
                requestId,
                initialDriverEta,
                userCoupon,
                couponDiscountAmount,
                offer: { id: offerId, numberOfSeats, name, avatar },
                price,
                createdAt,
              },
              index
            ) => (
              <div
                key={id}
                className={classNames(
                  'bg-white dark__bg-1100 p-x1 rounded-3 shadow-sm',
                  {
                    'mt-3': index !== 0,
                  }
                )}
              >
                <Row className="flex-between-center">
                  <Col xs={10}>
                    <p className="fw-semi-bold mb-0">
                      <Link to={`/categories`}>{name}</Link>
                    </p>
                  </Col>
                </Row>
                <Row className={`flex-between-center ${avatar ? 'my-3' : ''}`}>
                  <Col xs={10}>
                    {avatar ? (
                      <ImageOverview images={[avatar.link]}>
                        {setImgIndex => (
                          <Avatar
                            size="xl"
                            src={avatar?.link}
                            name={avatar?.name}
                            onClick={() => setImgIndex(0)}
                          />
                        )}
                      </ImageOverview>
                    ) : null}
                  </Col>
                </Row>
                <div className="border-bottom border-lg-0 border-0 my-3"></div>
                <Row className="flex-between-center">
                  <Col xs={12} lg={8}>
                    <Flex className="gap-1 h6" alignItems="center">
                      <FontAwesomeIcon
                        icon={faEuroSign}
                        transform="shrink-3 up-1"
                      />
                      <span className="text-600 lb-anywhere">{price}</span>
                    </Flex>
                    <Flex className="gap-1 h6" alignItems="center">
                      <FontAwesomeIcon
                        icon={faChair}
                        transform="shrink-3 up-1"
                      />
                      <span className="text-600">{numberOfSeats}</span>
                    </Flex>
                    <Flex className="gap-1 h6 mb-0" alignItems="center">
                      <FontAwesomeIcon
                        icon={faHourglass}
                        transform="shrink-3 up-1"
                      />
                      <span className="text-600">
                        {initialDriverEta}{' '}
                        {initialDriverEta === 1 ? 'minute' : 'minutes'}
                      </span>
                    </Flex>
                  </Col>
                  <Col xs={12} lg={'auto'}>
                    <div className="border-bottom border-lg-0 border-0 my-lg-0 my-3"></div>
                    <Flex direction="column">
                      {/* <SoftBadge bg={"primary"}>Employé</SoftBadge> */}
                      <h6 className="d-flex align-items-center gap-1 mb-0 mt-2">
                        <FontAwesomeIcon
                          icon={faClock}
                          transform="shrink-3 up-1"
                        />
                        <span className="text-600">
                          {DateTime.fromISO(createdAt)
                            .setLocale('fr')
                            .toFormat('dd LLL yyyy')}
                        </span>
                      </h6>
                    </Flex>
                  </Col>
                </Row>

                <RequestPaymentCard id={requestId} offerId={id} />

                {couponDiscountAmount && userCoupon?.coupon && (
                  <Row className="flex-between-center">
                    <Col md={4}>
                      <StatisticsCard
                        title={userCoupon.coupon.name}
                        value={couponDiscountAmount ?? 0}
                        prefix={'€'}
                        badgeBg="success"
                        linkText="Add transaction"
                        onClick={() =>
                          navigate(`/coupons/${userCoupon.coupon.id}`)
                        }
                      />
                    </Col>
                  </Row>
                )}
              </div>
            )
          )
        ) : (
          <p>No offers found for this request.</p>
        )}
      </Fragment>
    </>
  );
};

export default RequestOffersCard;
