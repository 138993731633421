import { IPaginateResponse } from '@/interfaces/Fetch';
import api from './api';
import { IBooking, IBookingFilters } from '@/interfaces/Booking';

export const bookingApi = api.injectEndpoints({
  endpoints: build => ({
    getBookings: build.query<IPaginateResponse<IBooking>, IBookingFilters>({
      query: data => ({
        url: '/v2/bookings',
        params: data,
      }),
      providesTags: ['Booking'],
    }),
    getBookingById: build.query({
      query: id => ({
        url: `/v2/bookings/${id}`,
      }),
      providesTags: (result, _err, _body) => [
        { type: 'Booking', id: result?.id },
      ],
    }),
    updateBooking: build.mutation({
      query: ([id, body]) => ({
        url: `/v2/bookings/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    updateBookingStatus: build.mutation({
      query: ([id, body]) => ({
        url: `/v2/bookings/${id}/status`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Booking'],
    }),
    assingDriver: build.mutation({
      query: body => ({
        url: '/v2/bookings/assign',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Booking'],
    }),
  }),
});

export const {
  useGetBookingsQuery,
  useGetBookingByIdQuery,
  useUpdateBookingMutation,
  useAssingDriverMutation,
  useUpdateBookingStatusMutation,
} = bookingApi;
