import Avatar from '@/components/common/Avatar';
import Flex from '@/components/common/Flex';
import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import UnknownBadge from '@/components/common/UnknownBadge';
import BookingHeader from '@/components/table-headers/bookings/BookingHeader';
import { BookingStatus, IBooking, IBookingFilters } from '@/interfaces/Booking';
import { useGetBookingsQuery } from '@/services/bookings';
import { faEdit, faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const BookingView = () => {
  const [state, setState] = useState<Partial<IBookingFilters>>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data, isLoading, isFetching } = useGetBookingsQuery({
    ...state.pagination,
    query: state.globalFilter,
    status: state.status,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const getStatusColor = useCallback((status: string) => {
    switch (status.toUpperCase()) {
      case BookingStatus.BOOKED:
      case BookingStatus.COMPLETED:
        return 'success';
      case BookingStatus.CANCELLED:
        return 'danger';
      case BookingStatus.DRIVER_ARRIVED:
      case BookingStatus.ON_GOING:
        return 'info';
      default:
        return 'info';
    }
  }, []);

  const truncate = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  const columns = useMemo<ColumnDef<IBooking>[]>(
    () => [
      {
        header: 'Actions',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ id }) => (
          <>
            <Link to={`/bookings/${id}`} className="btn btn-primary btn-sm">
              <FontAwesomeIcon icon={faEye} />
            </Link>
            <Link
              to={`/bookings/${id}/edit`}
              className="btn btn-info btn-sm ms-2"
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
            <Button
              variant="danger"
              size="sm"
              className="ms-2"
              onClick={() => {}}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </>
        ),
        id: 'actions',
        cell: info => info.getValue(),
      },
      {
        header: 'From\nTo',
        footer: props => props.column.id,
        accessorFn: ({ originAddress, destinationAddress }) => {
          return (
            <>
              {truncate(originAddress.replace(/, France$/, ''), 45)}
              <p className="mb-0 text-500">
                {truncate(destinationAddress.replace(/, France$/, ''), 45)}
              </p>
            </>
          );
        },
        id: 'origin',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '25rem', maxWidth: '35rem' },
          },
          cellProps: {
            style: { minWidth: '25rem', maxWidth: '35rem' },
            className: 'text-wrap',
          },
        },
      },
      {
        header: 'Start Date\nStart Time',
        footer: props => props.column.id,
        accessorFn: ({ startDate, time }) => (
          <>
            {DateTime.fromISO(startDate).toFormat('dd/MM/yyyy')}
            <p className="mb-0 text-500">
              {DateTime.fromISO(time).toFormat('HH:mm')}
            </p>
          </>
        ),
        id: 'startDate',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '9rem', maxWidth: '25rem' },
          },
          cellProps: {
            style: { minWidth: '9rem', maxWidth: '25rem' },
            className: 'text-wrap',
          },
        },
      },
      {
        header: 'Status',
        footer: props => props.column.id,
        accessorFn: ({ status }) => (
          <SoftBadge bg={getStatusColor(status)} className="text-capitalize">
            {status.toUpperCase()}
          </SoftBadge>
        ),
        id: 'status',
        cell: info => info.getValue(),
      },
      {
        header: 'Price',
        footer: props => props.column.id,
        accessorFn: ({ price }) => <>{(parseInt(price) / 100).toFixed(2)} €</>,
        id: 'price',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '7rem', maxWidth: '15rem' },
          },
          cellProps: {
            style: { minWidth: '7rem', maxWidth: '15rem' },
          },
        },
      },
      {
        header: 'Guest Name',
        footer: props => props.column.id,
        accessorFn: ({ passengerFirstname, passengerLastname }) => (
          <>
            {passengerFirstname} {passengerLastname}
          </>
        ),
        id: 'passenger',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '13rem', maxWidth: '15rem' },
          },
          cellProps: {
            style: { minWidth: '13rem', maxWidth: '15rem' },
            className: 'text-wrap',
          },
        },
      },
      {
        header: 'Flight/Train',
        footer: props => props.column.id,
        accessorFn: ({ flightNumber, trainNumber }) => {
          return flightNumber || trainNumber;
        },
        id: 'flightNumber',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '5rem', maxWidth: '15rem' },
          },
          cellProps: {
            style: { minWidth: '5rem', maxWidth: '15rem' },
            className: 'text-wrap',
          },
        },
      },
      {
        header: 'Language',
        footer: props => props.column.id,
        accessorFn: ({ language }) => language,
        id: 'language',
        cell: info => info.getValue(),
      },
      {
        header: 'Comments',
        footer: props => props.column.id,
        accessorFn: ({ comments }) => truncate(comments, 50),
        id: 'comments',
        cell: info => info.getValue(),
      },
      {
        header: 'Driver',
        footer: props => props.column.id,
        accessorFn: ({ driverFirstname, driverLastname, driverId, avatar }) => {
          return driverId ? (
            <Link to={`/drivers/${driverId}`}>
              <Flex alignItems="center">
                {avatar && (
                  <Avatar
                    src={avatar}
                    alt={`${driverFirstname} Avatar`}
                    className="me-2"
                    size="2xl"
                  />
                )}
                <p className="mb-0">
                  <span>{driverFirstname}</span>
                  <br />
                  <span>{driverLastname}</span>
                </p>
              </Flex>
            </Link>
          ) : (
            'N/A'
          );
        },
        id: 'drivers',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '10rem', maxWidth: '15rem' },
          },
          cellProps: {
            style: { minWidth: '10rem', maxWidth: '15rem' },
            className: 'text-wrap',
          },
        },
      },
      {
        header: 'Car Brand\nCar Plate',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ brand, model, licensePlate }) =>
          brand ? (
            <>
              {`${brand} - ${model}`}
              <p className="mb-0 text-500">{licensePlate}</p>
            </>
          ) : (
            <UnknownBadge />
          ),
        id: 'defaultVehicle',
        cell: info => info.getValue(),
      },
      {
        header: 'Created Date\nCreated Time',
        footer: props => props.column.id,
        accessorFn: ({ createdAt }) => (
          <>
            {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
            <p className="mb-0 text-500">
              {DateTime.fromISO(createdAt).toFormat('HH:mm')}
            </p>
          </>
        ),
        id: 'createdAt',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '9rem', maxWidth: '25rem' },
          },
          cellProps: {
            style: { minWidth: '9rem', maxWidth: '25rem' },
            className: 'text-wrap',
          },
        },
      },
      {
        header: 'Author Name',
        footer: props => props.column.id,
        accessorFn: ({ authorFirstname, authorLastname, authorId }) => (
          <Link to={`/users/${authorId}`}>
            {authorFirstname} {authorLastname}
          </Link>
        ),
        id: 'author',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '13rem', maxWidth: '15rem' },
          },
          cellProps: {
            style: { minWidth: '13rem', maxWidth: '15rem' },
            className: 'text-wrap',
          },
        },
      },
    ],
    []
  );

  return (
    <Table
      HeaderComponent={BookingHeader}
      columns={columns}
      state={state}
      onStateChange={setState}
      loading={isLoading || isFetching}
      data={data?.data}
      meta={data?.meta}
    />
  );
};

export default BookingView;
