import React, { FC } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const Timeline: FC<TimelineProps> = ({ VerticalTimelineData }) => {
  return (
    <>
      <div className="timeline-vertical">
        {VerticalTimelineData.map((item, index) => {
          const { year, date, title, icon } = item;
          return (
            <div
              key={index}
              className={classNames('timeline-item ', {
                'timeline-item-start': index % 2 === 0,
                'timeline-item-end': index % 2 !== 0,
              })}
            >
              <div className="timeline-icon icon-item icon-item-lg text-primary border-300">
                <FontAwesomeIcon icon={icon} className="fs-1" />
              </div>
              <Row
                className={` ${
                  index % 2 == 0 ? 'timeline-item-start' : 'timeline-item-end'
                }`}
              >
                <Col lg={6} className="timeline-item-time">
                  <div>
                    <p className="fs--1 mb-0 fw-semi-bold"> {year}</p>
                    <p className="fs--2 text-600">{date}</p>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="timeline-item-content">
                    <div className="timeline-item-card">
                      <p className="fs--1 mb-0">{title}</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          );
        })}
      </div>
    </>
  );
};

type TimelineProps = {
  VerticalTimelineData: Array<{
    title: string;
    year: string;
    date: string;
    icon: IconDefinition;
  }>;
};

export default Timeline;
