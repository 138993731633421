import Modal from '@/components/common/Modal';
import { IModalMethods } from '@/interfaces/Modal';
import { ICategoryForm } from '@/interfaces/Category';
import { useStoreCategoryMutation } from '@/services/categories';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

const StoreCategoryModal: ForwardRefRenderFunction<IModalMethods> = (_, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<ICategoryForm>({});

  const [storeOfferAsync] = useStoreCategoryMutation();

  const showOrHiddenModal = () => ModalRef.current?.showOrHiddenModal();

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<ICategoryForm> = ({ ...data }) => {
    toast.promise(
      storeOfferAsync({
        ...data,
      }).unwrap(),
      {
        pending: 'Ajout du offer en cours...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Le offer à bien été ajouté 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<ICategoryForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Ajouter une categorie"
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Name <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : Berline"
              type="text"
              {...register('name', {
                required: 'Ce champ est requis.',
                minLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au moins 2 caractères.',
                },
                maxLength: {
                  value: 255,
                  message: 'Ce champ doit contenir au maximum 255 caractères.',
                },
              })}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type="invalid">
              {errors.name?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Number of Seats <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Control
              placeholder="ex : 4"
              type="number"
              {...register('numberOfSeats', {
                required: 'Ce champ est requis..',
                minLength: {
                  value: 1,
                  message: 'Ce champ doit contenir au moins 1 caractères',
                },
                maxLength: {
                  value: 2,
                  message: 'Ce champ doit contenir au maximum 2 caractères',
                },
              })}
              isInvalid={!!errors.numberOfSeats}
            />
            <Form.Control.Feedback type="invalid">
              {errors.numberOfSeats?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>Avatar :</Form.Label>
            <Form.Control type="file" {...register('avatar')} />
            <Form.Control.Feedback type="invalid">
              {errors.avatar?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Eligible Van ? <span className="text-danger">*</span> :
            </Form.Label>
            <Form.Check
              type="checkbox"
              {...register('isForVanOnly')}
              isInvalid={!!errors.isForVanOnly}
            />
            <Form.Control.Feedback type="invalid">
              {errors.isForVanOnly?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Eligible Berlin ? <span className="text-danger">*</span>
            </Form.Label>
            <Form.Check
              type="checkbox"
              {...register('isForBerlineOnly')}
              isInvalid={!!errors.isForBerlineOnly?.message}
            />
            <Form.Control.Feedback type="invalid">
              {errors.isForBerlineOnly?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col lg={6}></Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(StoreCategoryModal);
