import { useGetBookingByIdQuery } from '@/services/bookings';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

interface UpdateBookingStatusModalProps {
  bookingId: string;
  onUpdate: (
    bookingId: string,
    newStatus: string,
  ) => void;
}

export interface UpdateBookingStatusModalHandle {
  showOrHiddenModal: () => void;
}

const UpdateBookingStatusModal = forwardRef<
  UpdateBookingStatusModalHandle,
  UpdateBookingStatusModalProps
>(({ bookingId, onUpdate }, ref) => {
  const [show, setShow] = useState(false);
  const [status, setStatus] = useState('');

  const { data } = useGetBookingByIdQuery(bookingId);

  useImperativeHandle(ref, () => ({
    showOrHiddenModal: () => setShow(!show),
  }));

  const handleUpdate = () => {
    onUpdate(bookingId, status);
    setShow(false);
  };

  useEffect(() => {
    if(data) {
      setStatus(data.status.toUpperCase());
    }
  }, [data]);

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Change Booking Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="statusSelect">
            <Form.Label>Status</Form.Label>
            <Form.Control
              as="select"
              value={status}
              onChange={e => {
                setStatus(e.target.value);
              }}
            >
              <option value="">Select status</option>
              <option value="BOOKED">Booked</option>
              <option value="DRIVER_ARRIVED">Driver Arrived</option>
              <option value="ONGOING">On Going</option>
              <option value="COMPLETED">Completed</option>
              <option value="CANCELLED">Cancelled</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={handleUpdate}>
          Update Status
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

export default UpdateBookingStatusModal;
