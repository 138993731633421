import SoftBadge from '@/components/common/SoftBadge';
import Table from '@/components/common/table/Table';
import UpdatableCell from '@/components/common/table/UpdatableCell';
import AllPriceControllersHeader from '@/components/table-headers/priceControllers/AllPriceControllersHeader';
import { INVALID_FORM_MESSAGE } from '@/constants/validatorMessages';
import IPriceController, {
  IPriceControllerForm,
} from '@/interfaces/PriceController';
import { IUserFilters } from '@/interfaces/User';
import {
  useGetBookingPriceControllersQuery,
  useUpdateBookingPriceControllerMutation,
} from '@/services/bookingPriceController';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { Fragment, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  SubmitErrorHandler,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast, ToastContentProps } from 'react-toastify';

const BookingPriceControllerView = () => {
  const [updateBookingPriceController] =
    useUpdateBookingPriceControllerMutation();
  const [state, setState] = useState<Partial<TableState> & IUserFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 15,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error } =
    useGetBookingPriceControllersQuery({
      ...state.pagination,
      query: state.globalFilter,
      ...(state.sorting?.[0] && {
        sortBy: state.sorting[0].id,
        sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
      }),
    });

  const { control, handleSubmit, reset } = useForm<IPriceControllerForm>();

  const { append } = useFieldArray({
    control,
    name: 'data',
  });

  const onUpdate = (id: string, columnId: string, value: any) => {
    append([
      {
        id,
        [columnId]: parseFloat(value),
      } as IPriceControllerForm['data'][0],
    ]);
  };

  const onSubmit: SubmitHandler<IPriceControllerForm> = async data => {
    // console.log(data)
    try {
      await toast.promise(updateBookingPriceController(data).unwrap, {
        pending: 'Modification de la colonne en cours...',
        success: {
          render() {
            reset();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Le price controller à bien été modifié 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }
            return 'Une erreur est survenue';
          },
        },
      });
    } catch (error) {
      toast.error('Une erreur est survenue');
    }
  };

  const onError: SubmitErrorHandler<IPriceControllerForm> = () =>
    toast.error(INVALID_FORM_MESSAGE);

  const columns = useMemo<ColumnDef<IPriceController>[]>(
    () => [
      {
        header: 'Offre',
        footer: props => props.column.id,
        accessorFn: ({ offer }) => (
          <Link to={`/offers/${offer.id}`}>
            <SoftBadge bg={'primary'} className={'my-2 text-xl-center'}>
              {offer.name}
            </SoftBadge>
          </Link>
        ),
        id: 'roles',
        cell: info => info.getValue(),
      },
      {
        header: 'Minimum',
        footer: props => props.column.id,
        accessorFn: ({ min }) => min.toFixed(2),
        id: 'min',
        cell: cell => (
          <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
        ),
      },
      {
        header: 'Base',
        footer: props => props.column.id,
        accessorFn: ({ base }) => base.toFixed(2),
        id: 'base',
        cell: cell => (
          <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
        ),
      },
      {
        header: 'Distance',
        footer: props => props.column.id,
        accessorFn: ({ distance }) => distance.toFixed(2),
        id: 'distance',
        cell: cell => (
          <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
        ),
      },
      {
        header: 'Duration',
        footer: props => props.column.id,
        accessorFn: ({ duration }) => duration.toFixed(2),
        id: 'duration',
        cell: cell => (
          <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
        ),
      },
      {
        header: 'Stops',
        footer: props => props.column.id,
        accessorFn: ({ stop }) => stop.toFixed(2),
        id: 'stop',
        cell: cell => (
          <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
        ),
      },
      {
        header: 'D&S Factor',
        footer: props => props.column.id,
        accessorFn: ({ demandAndSupply }) => demandAndSupply.toFixed(2),
        id: 'demandAndSupply',
        cell: cell => (
          <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
        ),
      },
      {
        header: 'Discount (%)',
        footer: props => props.column.id,
        accessorFn: ({ discount }) => discount,
        id: 'discount',
        cell: cell => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
            <span style={{ marginLeft: '0.5rem' }}>%</span>
          </div>
        ),
      },
      {
        header: 'Free Time (mn)',
        footer: props => props.column.id,
        accessorFn: ({ freeWaitingTime }) => freeWaitingTime,
        id: 'freeWaitingTime',
        cell: cell => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
            <span style={{ marginLeft: '0.5rem' }}>
              {cell.row.original.freeWaitingTime === 1 ? 'min' : 'mins'}
            </span>
          </div>
        ),
      },
      {
        header: 'Minimum Charge',
        footer: props => props.column.id,
        accessorFn: ({ minimumChargeAfterFreeWait }) =>
          minimumChargeAfterFreeWait.toFixed(2),
        id: 'minimumChargeAfterFreeWait',
        cell: cell => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
          </div>
        ),
      },
      {
        header: '€ / min',
        footer: props => props.column.id,
        accessorFn: ({ chargeAfterFreeWait }) => chargeAfterFreeWait.toFixed(2),
        id: 'chargeAfterFreeWait',
        cell: cell => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UpdatableCell cellProps={cell} type="number" onUpdate={onUpdate} />
            <span style={{ marginLeft: '0.5rem' }}>€</span>
          </div>
        ),
      },
      {
        header: 'Crée le',
        footer: props => props.column.id,
        accessorFn: ({ createdAt }) =>
          DateTime.fromISO(createdAt).toFormat('dd-MM-yyyy à HH:mm:ss'),
        id: 'createdAt',
        cell: info => info.getValue(),
      },
      {
        header: '#',
        footer: props => props.column.id,
        accessorFn: ({ id }) => id,
        id: 'id',
        cell: info => info.getValue(),
      },
    ],
    []
  );

  return (
    <Fragment>
      <Table
        HeaderComponent={AllPriceControllersHeader}
        columns={columns}
        state={state}
        tableName="priceController"
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
      <Button className="mt-3" onClick={handleSubmit(onSubmit, onError)}>
        Save Changes
      </Button>
    </Fragment>
  );
};

export default BookingPriceControllerView;
