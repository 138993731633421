import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import Flex from '@/components/common/Flex';
import Loader from '@/components/common/Loader';
import Table from '@/components/common/table/Table';
import AllRequestsHeader from '@/components/table-headers/requests/AllRequestsHeader';
import { IUserFilters } from '@/interfaces/User';
import { IRequest } from '@/interfaces/Request';
import { useGetRequestsQuery } from '@/services/requests';
import { selectCurrentUser } from '@/services/slices/authSlice';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const RequestsView: FC = () => {
  const [requestsData, setRequestsData] = useState<IRequest[]>([]);
  const user = useSelector(selectCurrentUser);

  const [state, setState] = useState<Partial<TableState> & IUserFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 150,
    },
    globalFilter: '',
    sorting: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
  });

  const { data, isLoading, isError, error } = useGetRequestsQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  useEffect(() => {
    if (data) {
      setRequestsData(data.data || []);
    }
  }, [data]);

  const totalCompanies = data?.meta?.total;

  const columns: ColumnDef<IRequest>[] = useMemo(
    () => [
      {
        header: 'Actions',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ id }) => (
          <Link to={`/requests/${id}`} className="btn btn-primary btn-sm">
            <FontAwesomeIcon icon={faEye} />
          </Link>
        ),
        id: 'actions',
        cell: info => info.getValue(),
      },
      {
        header: '#',
        id: 'number',
        cell: info => {
          if (totalCompanies === undefined) {
            return 'Loading...';
          }
          const pageIndex = info.table.getState().pagination.pageIndex;
          const pageSize = info.table.getState().pagination.pageSize;
          const rowIndex = info.row.index;
          const recordNumber =
            totalCompanies - (pageIndex * pageSize + rowIndex);
          return recordNumber;
        },
      },
      {
        header: 'Date\nTime',
        footer: props => props.column.id,
        enableSorting: true,
        accessorFn: ({ createdAt }) => (
          <>
            {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
            <p className="mb-0 text-500">
              {DateTime.fromISO(createdAt).toFormat('HH:mm')}
            </p>
          </>
        ),
        id: 'createdAt',
        cell: info => info.getValue(),
      },
      {
        header: 'User Name',
        footer: props => props.column.id,
        enableSorting: false,
        accessorFn: ({ author: { id, fullname, avatar, firstname } }) => (
          <>
            <Link to={`/users/${id}`}>
              <Flex alignItems="center">
                {avatar && (
                  <Avatar
                    src={avatar.link}
                    alt={`${firstname} Avatar`}
                    className="me-2"
                    size="2xl"
                  />
                )}
                <span>
                  {fullname} {user?.id === id && <b>(Moi)</b>}
                </span>
              </Flex>
            </Link>
          </>
        ),
        id: 'author',
        cell: info => info.getValue(),
      },
      {
        header: 'Start Address\nDestination Address',
        footer: props => props.column.id,
        enableSorting: false,
        accessorFn: ({ origin, destination, stops }) => (
          <>
            {origin.address}
            <p className="mb-0">{destination.address}</p>
          </>
        ),
        id: 'origin.address',
        cell: info => info.getValue(),
      },
      // {
      //   header: 'Ride Status\nPaymentStatus',
      //   footer: props => props.column.id,
      //   enableSorting: false,
      //   accessorFn: ({ }),
      //   id: 'ridePaymentStatus',
      //   cell: info => info.getValue()
      // },
      // {
      //   header: 'COMIN Price\nCOMIN ETA',
      //   footer: props => props.column.id,
      //   enableSorting: false,
      //   accessorFn: ({ offers }) => {
      //     if (offers && offers.length > 0) {
      //       return (
      //         <div className="text-secondary fs-1">
      //           <Badge bg="300" className="mx-1 p-2">
      //             {offers[0]?.driverPrice}{' '}
      //           </Badge>
      //           {offers[0]?.initialDriverEta}{' '}
      //         </div>
      //       );
      //     } else {
      //       return null;
      //     }
      //   },
      //   id: 'comin',
      //   cell: info => info.getValue(),
      // },
      // {
      //   header: 'Berline PriceBerline ETA',
      //   footer: props => props.column.id,
      //   enableSorting: false,
      //   accessorFn: {},
      //   id: 'berline',
      //   cell: info => info.getValue(),
      // },
      // {
      //   header: 'Van PriceVan ETA',
      //   footer: props => props.column.id,
      //   enableSorting: false,
      //   accessorFn: {},
      //   id: 'van',
      //   cell: info => info.getValue(),
      // },

      // {
      //   header: 'Coupon Name\nAmount',
      //   footer: props => props.column.id,
      //   enableSorting: false,
      //   accessorFn: ({ }),
      //   id: 'coupon',
      //   cell: info => info.getValue()
      // },
      // {
      //   header: 'Driver who\nreceived',
      //   footer: props => props.column.id,
      //   enableSorting: false,
      //   accessorFn: ({ }),
      //   id: 'coupon',
      //   cell: info => info.getValue()
      // },
      {
        header: 'Time & distance\nto pickup',
        footer: props => props.column.id,
        accessorFn: ({ initialEta, initialDistance }) => (
          <>
            <p className="mb-0">{initialEta} minutes</p>
            <p className="mb-0">{initialDistance} km</p>
          </>
        ),
        id: 'initialEta',
        cell: info => info.getValue(),
      },
      {
        header: 'Stops Count',
        footer: props => props.column.id,
        enableSorting: false,
        accessorFn: ({ stopsCount }) => stopsCount,
        id: 'stopsCount',
        cell: info => info.getValue(),
      },

      {
        header: 'COMIN Price',
        footer: props => props.column.id,
        enableSorting: false,
        accessorFn: ({ offers }) => {
          const offer = offers?.find(offer => offer.offer.name === 'COMIN');

          if (offer) {
            return offer.price.toFixed(2) + ' €';
          } else {
            return 'N/A';
          }
        },
        id: 'cominPrice',
        cell: info => info.getValue(),
      },
      {
        header: 'Berline Price',
        footer: props => props.column.id,
        enableSorting: false,
        accessorFn: ({ offers }) => {
          const offer = offers?.find(offer => offer.offer.name === 'Berline');

          if (offer) {
            return offer.price.toFixed(2)+ ' €';
          } else {
            return 'N/A';
          }
        },
        id: 'berlinePrice',
        cell: info => info.getValue(),
      },
      {
        header: 'Van Price',
        footer: props => props.column.id,
        enableSorting: false,
        accessorFn: ({ offers }) => {
          const offer = offers?.find(offer => offer.offer.name === 'Van');

          if (offer) {
            return offer.price.toFixed(2) + ' €';
          } else {
            return 'N/A';
          }
        },
        id: 'vanPrice',
        cell: info => info.getValue(),
      },
      {
        header: 'Request ID',
        footer: props => props.column.id,
        accessorFn: ({ id }) => id,
        id: 'id',
        cell: info => info.getValue(),
      },
    ],
    [totalCompanies, state.pagination?.pageIndex, state.pagination?.pageSize]
  );

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <Table
      HeaderComponent={AllRequestsHeader}
      columns={columns}
      state={state}
      tableName="requests"
      onStateChange={setState}
      data={data?.data}
      meta={data?.meta}
    />
  );
};

export default RequestsView;
