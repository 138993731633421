import { parse } from 'papaparse';
import { useCreateCompanyMutation, useLazyGetCompanyByTaxIdQuery } from '@/services/companies';
import { useLazyGetPlacesAutocompleteQuery } from '@/services/google/placeAutocomplete';
import { format } from 'date-fns';
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useLazyGetUserByEmailOrPhoneQuery, useStoreUserMutation, useUpdateUserMutation } from '@/services/users';
import { useStoreDriverMutation } from '@/services/drivers';
import { useGetAllVehiclesForCSVQuery } from '@/services/vehicles';
import { useLazyGetCategoriesQuery } from '@/services/categories';
import { getCorrespondingOffersFromVehicle } from '../helpers/OfferHelper';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import useFetchUsersInBatches from './useFetchUsersInBatches';
import { useImportDriversMutation } from '@/services/drivers';
import { useStoreDriverVehiclesForCSVMutation } from '@/services/drivers/vehicles';
import { DynamicData } from '@/helpers/TableHelper';
import { formCsvArrayToCompany } from '@/helpers/formCsvArrayToCompany';
import { formCSVToDriverVehicle } from '@/helpers/formCSVToDriverVehicle';
import { asyncThrottle, getMostProbableOccurrence } from '@/helpers/utils';
import ICategory from '@/interfaces/Category';
import IUser, { IUserFormSend } from '@/interfaces/User';
import { getFileFromUrl } from '@/services/files';
import { formCsvArrayToUser, normalizeUrl } from '@/helpers/UserHelpers';
import {
  formCsvToDriver,
  getReelNameFromDriverExperienceYearsForCSV,
  getReelNameFromDriverPrimaryShiftForCSV,
  getReelNameFromWeeklyDrivingHoursForCSV,
} from '@/helpers/DriverHelpers';
import { CompanyFormCreateSend } from '@/interfaces/Company';
import { PlaceAutocompleteResult } from '@/interfaces/PlaceAutocomplete';

export const useSendDrivers = (
  setImportSummary: React.Dispatch<
    React.SetStateAction<{
      successCount: number;
      failCount: number;
      failedImports: Array<{
        phone: string;
        email: string;
        name: string;
        error: string;
      }>;
    } | null>
  >
) => {
  const [storeCompany] = useCreateCompanyMutation();
  const [storeUser] = useStoreUserMutation();
  const [updateUser] = useUpdateUserMutation();
  const [storeDriver] = useStoreDriverMutation();
  const { data: vehicles } = useGetAllVehiclesForCSVQuery();
  const [storeDriverVehicle] = useStoreDriverVehiclesForCSVMutation();
  const [getPlacesAutocompleteLazy] = useLazyGetPlacesAutocompleteQuery();
  const [getOffersQuery] = useLazyGetCategoriesQuery();
  const [importDrivers] = useImportDriversMutation();
  const [isSending, setIsSending] = useState(false);
  const [getCompanyByTaxId] = useLazyGetCompanyByTaxIdQuery();
  const [getUserByEmailOrPhone] = useLazyGetUserByEmailOrPhoneQuery();


  const { allUsers: users } = useFetchUsersInBatches(8000);

  function isFetchBaseQueryError(error: unknown): error is FetchBaseQueryError {
    return typeof error === 'object' && error != null && 'status' in error;
  }


  const getCompany = async (taxId: string) => {
    try {
      const response = await getCompanyByTaxId(taxId).unwrap();
      return response || null;
    } catch (error) {
      console.error('Error fetching company:', error);
      return null;
    }
  };
  
  
  
  const createCompany = async (file: DynamicData) => {
    const company = formCsvArrayToCompany(file);
  
    toast.info(`Processing Company-${company.name}...`, {
      position: 'bottom-right',
    });
  
    const existingCompany = await getCompany(company.taxId);
    if (existingCompany) {
      toast.info(`Company-${company.name} already exists`, {
        position: 'bottom-right',
      });
      return existingCompany;
    }
  
    const query = `${file.company_address} ${file.company_postal_code}`;
  
    try {
      const placeInfo = await getPlacesAutocompleteLazy({ query }).unwrap();
      if (placeInfo.length > 0) {
        const placeInfoObject = placeInfo[0] as Partial<PlaceAutocompleteResult>;
        const placeId = placeInfoObject.place_id || placeInfoObject.placeId;
  
        if (placeId) {
          company.addressId = placeId;
          company.owner.addressId = placeId;
          company.supportAddressId = placeId;
        }
      }
      const response = await storeCompany(company as CompanyFormCreateSend).unwrap();
      toast.success(`Company-${company.name} created successfully`, {
        position: 'bottom-right',
      });
      return response;
    } catch (error) {
      console.error('Failed to create company:', error);
      return null;
    }
  };
  

  const createUser = async (file: DynamicData) => {
    const user = formCsvArrayToUser(file);
    const { email, phone } = user;
  
    if (user.birthdate) {
      user.birthdate = format(new Date(user.birthdate), 'dd-MM-yyyy');
    }
  
    toast.info(`Processing User - ${email}`, { position: 'bottom-right' });
  
    try {
      const existingUser = await getUserByEmailOrPhone({ email, phone }).unwrap();
  
      if (existingUser) {
        if (existingUser.email !== email) {
          await patchUser(existingUser, email);
          toast.info(`Updated email for existing user: ${email}`, { position: 'bottom-right' });
        } else {
          toast.info(`User already exists with matching email: ${email}`, { position: 'bottom-right' });
        }
        return existingUser;
      }
  
      const createdUser = await storeUser({
        ...user,
        avatar: null,
      }).unwrap();
  
      if (user.avatar) {
        const avatarUrl = normalizeUrl(user.avatar);
        const { file } = await getFileFromUrl(avatarUrl);
        await patchUser(createdUser, undefined, file);
      }
  
      toast.success(`User - ${createdUser.email} created successfully`, {
        position: 'bottom-right',
      });
  
      return createdUser;
    } catch (e) {
      let errorMessage = 'Unknown error occurred';
      if (isFetchBaseQueryError(e)) {
        const apiError = e.data as { statusCode?: number; message?: string };
        errorMessage = apiError.message || errorMessage;
  
        if (errorMessage.includes('duplicate key value violates unique constraint "users_email_unique"')) {
          errorMessage = 'User already exists';
        } else if (errorMessage.includes('invalid input syntax for type')) {
          errorMessage = 'Invalid user data';
        }
      } else if (e instanceof Error) {
        errorMessage = e.message;
      }
  
      toast.error(`Failed to create user: ${errorMessage}`);
      return null;
    }
  };
  
  

  const createDriver = async (
    file: DynamicData,
    user: IUser,
    companyId: string
  ) => {
    const driver = formCsvToDriver(file);

    toast.info(`Sending Driver... ${user.firstname} ${user.lastname}`, {
      position: 'bottom-right',
    });

    try {
      const createdDriver = await storeDriver({
        ...driver,
        userId: user.id,
        weeklyDrivingHours:
          getReelNameFromWeeklyDrivingHoursForCSV(driver.weeklyDrivingHours) ??
          '31To40Hours',
        primaryShift: getReelNameFromDriverPrimaryShiftForCSV(driver.primaryShift),
        driverExperienceYears: getReelNameFromDriverExperienceYearsForCSV(
          driver.driverExperienceYears
        ),
        companyId,
        referralCode: String(driver.referralCode),
        commissionRate: { value: driver.commissionRate ?? 10, label: '10%' }
      }).unwrap();
      

      toast.success(`Driver for ${user.email} created successfully`, {
        position: 'bottom-right',
      });

      return createdDriver;
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      if (isFetchBaseQueryError(error)) {
        const apiError = error.data as {
          statusCode?: number;
          message?: string;
        };

        errorMessage = apiError.message || errorMessage;

        if (
          errorMessage.includes(
            'duplicate key value violates unique constraint "drivers_license_number_unique"'
          )
        ) {
          errorMessage = 'Driver already exists';
        } else if (errorMessage.includes('invalid input syntax for type')) {
          errorMessage = 'Invalid driver data';
        }
      } else if (error instanceof Error) {
        errorMessage = error.message;
      }

      toast.error(`Failed to create driver: ${errorMessage}`, {
        position: 'top-center',
      });

      return null;
    }
  };

  const addVehicleToDriver = async (
    file: DynamicData,
    driverProfileId: string
  ) => {
    const driverVehicle = formCSVToDriverVehicle(file);

    toast.info(
      `Sending Driver vehicle... ${driverVehicle.vehicle.brand} ${driverVehicle.vehicle.model}`,
      {
        position: 'bottom-right',
      }
    );

    try {
      const vehicles_ = (vehicles?.data ?? []).map(
        v => `${v.brand} ${v.model}`
      );
      const vehicle = (vehicles?.data ?? []).filter(
        v =>
          v.fullname ===
          getMostProbableOccurrence(
            `${driverVehicle.vehicle.brand} ${driverVehicle.vehicle.model}`,
            vehicles_
          )
      )[0];

      if (vehicle) {
        const offers = await getOffersQuery({});
        const avalaibleVehicleOffersId = (
          await getCorrespondingOffersFromVehicle(
            vehicle,
            (offers.data?.data || []) as ICategory[]
          )
        ).filter((offerId): offerId is string => offerId !== undefined);

        const payload = {
          driverProfileId: driverProfileId,
          vehicleId: vehicle.id,
          colorLabel: driverVehicle.colorLabel,
          color: driverVehicle.color,
          licensePlate: driverVehicle.licensePlate,
          year: driverVehicle.year,
          availableOfferIds: avalaibleVehicleOffersId,
          default: true,
          offers: [],
        };

        const response = await storeDriverVehicle([
          driverProfileId,
          payload,
        ]).unwrap();

        toast.success(
          `Vehicle for Driver ID ${driverProfileId} sent successfully`,
          {
            position: 'bottom-right',
          }
        );

        return response;
      } else {
        throw new Error('Vehicle not found');
      }
    } catch (error) {
      let errorMessage = 'Unknown error occurred';

      if (error instanceof Error) {
        errorMessage = error.message;

        if (errorMessage.includes('Vehicle not found')) {
          errorMessage = 'Specified vehicle could not be found';
        }
      }

      toast.error(`Failed to add vehicle to driver: ${errorMessage}`, {
        position: 'top-center',
      });

      return null;
    }
  };

  const patchUser = async (user: IUser, newEmail?: string, file?: File) => {
    const newUser: Partial<IUserFormSend> = { avatar: null };

    if (file) {
      newUser.avatar = file;
    }

    if (newEmail && user.email !== newEmail) {
      newUser.email = newEmail;
    }

    const updatedUser = await updateUser([user.id, newUser]).unwrap();
    toast.success('User updated successfully', {
      position: 'bottom-right',
    });
    return updatedUser;
  };

  const throttledSendDriver = useCallback(
    asyncThrottle(async (file: File) => {
      setIsSending(true);
      let successCount = 0;
      let failCount = 0;
      const failedImports: Array<{
        phone: string;
        email: string;
        name: string;
        error: string;
      }> = [];

      try {
        const parsedData: DynamicData[] = await parseCsv(file);

        for (const data of parsedData) {
          const phone = data['Phone_Number'] || data['company_phone'] || 'N/A';
          const email =
            data['Company_email'] || data['lowercased_email'] || 'N/A';
          const name = `${
            data['firstname_formatted'] ||
            data['company_owner_firstname'] ||
            'Unknown'
          } ${
            data['lastname_formatted'] ||
            data['Company_Owner_Lastname'] ||
            'Unknown'
          }`;

          try {
            const company = await createCompany(data);
            if (!company) {
              throw new Error('Failed to create company');
            }

            const user = await createUser(data);
            if (!user) {
              throw new Error('Failed to create user');
            }

            const driver = await createDriver(data, user, company.id);
            if (!driver) {
              throw new Error('Failed to create driver profile');
            }

            await addVehicleToDriver(data, driver.id);

            successCount++;
            setImportSummary(prev => ({
              successCount,
              failCount: prev?.failCount || 0,
              failedImports: prev?.failedImports || [],
            }));
          } catch (err) {
            failCount++;
            let errorMessage = 'Unknown error occurred';

            if (err instanceof Error) {
              errorMessage = err.message;
            }

            console.error('Error processing record:', errorMessage);

            failedImports.push({
              phone,
              email,
              name,
              error: errorMessage,
            });

            setImportSummary(prev => ({
              successCount: prev?.successCount || 0,
              failCount,
              failedImports: [...failedImports],
            }));
          }
        }

        setIsSending(false);

        return {
          success: true,
          result: {
            successCount,
            failCount,
            failedImports,
          },
        };
      } catch (error) {
        console.error('Error uploading file:', error);

        setIsSending(false);
        return {
          success: false,
          result: {
            successCount,
            failCount,
            failedImports,
          },
        };
      }
    }, 300),
    [users]
  );

  return { throttledSendDriver, isSending };
};

const parseCsv = (file: File): Promise<DynamicData[]> => {
  return new Promise((resolve, reject) => {
    parse(file, {
      header: true,
      complete: results => {
        console.log('Parsed CSV data:', results.data);
        resolve(results.data as DynamicData[]);
      },
      error: error => {
        console.error('Error parsing CSV:', error);
        reject(error);
      },
    });
  });
};
