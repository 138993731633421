import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import { ICardDetailsProps } from '@/interfaces/Card';
import { FC, useEffect, useState } from 'react';
import { Badge, Card, Col, Row, Table } from 'react-bootstrap';
import { useGetRideQuery } from '@/services/rides';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBan,
  faCar,
  faCarAlt,
  faCarBurst,
  faCarCrash,
  faCaretDown,
  faCaretUp,
  faCarOn,
  faCarSide,
  faCheck,
  faDesktop,
  faMobile,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { GOOGLEMAPSWEBLINK } from '@/constants/places';
import SoftBadge from '../../common/SoftBadge';
import { DateTime } from 'luxon';
import IRide from '@/interfaces/Ride';
import {
  calculateAcceptanceTime,
  calculateFinalETA,
  calculatePercentageChange,
  calculateDistance,
  calculatePickupDetails,
} from '@/helpers/utils';
import {
  getColorFromRideStatus,
  getReelRideStatus,
} from '@/helpers/UserHelpers';
import UnknownBadge from '@/components/common/UnknownBadge';
import Timeline from '@/components/common/Timeline';
import {
  useGetRequestQuery,
  useLazyGetRequestQuery,
} from '@/services/requests';
import { IRequest } from '@/interfaces/Request';

const RideDetailProgressStatusTimeStampCard: FC<ICardDetailsProps<IRide>> = ({
  id,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetRideQuery(id);
  const [
    getRequest,
    {
      data: request,
      isLoading: isRequestLoading,
      isError: isRequestError,
      error: requestError,
    },
  ] = useLazyGetRequestQuery();

  useEffect(() => {
    if (data) {
      callBack?.(data);
      getRequest(data.offer.requestId)
        .unwrap()
        .then(requestData => {
          setVerticalTimelineData(setUpVerticalTimelineData(data, requestData));
        });
    }
  }, [data]);

  const [verticalTimelineData, setVerticalTimelineData] = useState<any>([]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  //Calculate Final ETA
  const driverArrivalTime = data.driverArrivedAt
    ? new Date(data.driverArrivedAt).getTime()
    : 0;
  const rideCompletionTime = data.completedAt
    ? new Date(data.completedAt).getTime()
    : 0;
  const finalETA = calculateFinalETA(
    new Date(driverArrivalTime),
    new Date(rideCompletionTime),
    data.offer.initialDriverEta
  );

  //Calculate acceptance time
  const acceptanceTime = calculateAcceptanceTime(
    new Date(data.createdAt),
    new Date(data.updatedAt)
  );

  return (
    <Card className="font-sans-serif ">
      <Card.Body>
        <h4>Ride steps</h4>
        <Timeline VerticalTimelineData={verticalTimelineData} />
      </Card.Body>
    </Card>
  );
};

const setUpVerticalTimelineData = (data: IRide, request: IRequest) => {
  const verticalTimelineData = [];
  if (!data) return [];

  verticalTimelineData.push({
    title: 'Searching rides...',
    year: formatDate(request.createdAt).time,
    date: formatDate(request.createdAt).date,
    icon: faMobile,
  });

  if (data.status === 'no_drivers_available') {
    verticalTimelineData.push({
      title: 'No driver found',
      year: formatDate(data.updatedAt).time,
      date: formatDate(data.updatedAt).date,
      icon: faBan,
    });
    return verticalTimelineData;
  }
  verticalTimelineData.push({
    title: 'Driver accepted',
    year: formatDate(data.createdAt).time,
    date: formatDate(data.createdAt).date,
    icon: faCarOn,
  });

  if (data.cancelledAt) {
    verticalTimelineData.push({
      title: 'User cancelled',
      year: formatDate(data.cancelledAt).time,
      date: formatDate(data.cancelledAt).date,
      icon: faBan,
    });
    return verticalTimelineData;
  }

  if (data.cancelledAt) {
    verticalTimelineData.push({
      title: 'Driver cancelled',
      year: formatDate(data.cancelledAt).time,
      date: formatDate(data.cancelledAt).date,
      icon: faCarCrash,
    });
    return verticalTimelineData;
  }

  if (data.driverArrivedAt) {
    verticalTimelineData.push({
      title: 'Driver arrived',
      year: formatDate(data.driverArrivedAt).time,
      date: formatDate(data.driverArrivedAt).date,
      icon: faCar,
    });
  }
  if (data.startedAt) {
    verticalTimelineData.push({
      title: 'Driver started',
      year: formatDate(data.startedAt).time,
      date: formatDate(data.startedAt).date,
      icon: faCarSide,
    });
  }

  if (data.status === 'completed' && data.completedAt) {
    verticalTimelineData.push({
      title: 'Ride completed',
      year: formatDate(data.completedAt).time,
      date: formatDate(data.completedAt).date,
      icon: faCheck,
    });
  }
  return verticalTimelineData;
};

const formatDate = (date: string) => {
  return {
    date: DateTime.fromISO(date).toFormat('dd LLL yyyy'),
    time: DateTime.fromISO(date).toFormat('HH:mm:ss'),
  };
};

export default RideDetailProgressStatusTimeStampCard;
