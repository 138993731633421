import Flex from '@/components/common/Flex';
import IconButton from '@/components/common/IconButton';
import { IDefaultCardProps } from '@/components/interfaces/Card';
import {
  faArrowLeft,
  faEdit,
  faToggleOn,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FC, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import UpdateBookingStatusModal, {
  UpdateBookingStatusModalHandle,
} from '@/components/modals/booking/UpdateBookingStatus';
import {
  useAssingDriverMutation,
  useUpdateBookingStatusMutation,
} from '@/services/bookings';
import {
  AssignDriverToBookingModal,
  AssingDriverToBookingModalHandle,
} from '@/components/modals/booking/AssignDriverToBookingModal';

const BookingCardHeader: FC<IDefaultCardProps> = ({ id, isEdit }) => {
  const navigation = useNavigate();
  const UpdateBookingStatusModalRef =
    useRef<UpdateBookingStatusModalHandle>(null);
  const AssignDriverToBookingModalRef =
    useRef<AssingDriverToBookingModalHandle>(null);

  const [updateBookingStatusAsync] = useUpdateBookingStatusMutation();
  const [assignDriverAsync] = useAssingDriverMutation();

  const handleStatusUpdate = async (bookingId: string, newStatus: string) => {
    try {
      await updateBookingStatusAsync([
        bookingId,
        { status: newStatus },
      ]).unwrap();
      toast.success('Booking status updated successfully');
    } catch {
      toast.error('Failed to update ride status');
    }
  };

  const handleAssignDriver = async (bookingId: string, driverId: string) => {
    try {
      await assignDriverAsync({ bookingId, driverId }).unwrap();
      toast.success('Driver assigned successfully');
    } catch {
      toast.error('Failed to assign driver');
    }
  };

  return (
    <Card className="mb-3">
      <UpdateBookingStatusModal
        ref={UpdateBookingStatusModalRef}
        bookingId={id}
        onUpdate={handleStatusUpdate}
      />
      <AssignDriverToBookingModal
        ref={AssignDriverToBookingModalRef}
        bookingId={id}
        onAssign={handleAssignDriver}
      />
      <Card.Header className="d-flex flex-between-center">
        <IconButton
          onClick={() => navigation(-1)}
          variant="nolina-default"
          size="sm"
          icon={faArrowLeft}
        />
        <Flex>
          <div
            className="bg-300 mx-3 d-xl-none"
            style={{ width: '1px', height: '29px' }}
          ></div>
          {!isEdit && (
            <>
              <IconButton
                onClick={() =>
                  AssignDriverToBookingModalRef.current?.showOrHiddenModal()
                }
                variant="nolina-default"
                size="sm"
                icon={faUser}
                transform="shrink-2"
                iconAlign="middle"
                className="me-2"
              >
                <span className="d-none d-xl-inline-block ms-1">
                  Assign Driver
                </span>
              </IconButton>
              <IconButton
                href={`/bookings/${id}/edit`}
                variant="nolina-default"
                size="sm"
                icon={faEdit}
                transform="shrink-2"
                iconAlign="middle"
                className="me-2"
              >
                <span className="d-none d-xl-inline-block ms-1">Modifier</span>
              </IconButton>
              <IconButton
                onClick={() =>
                  UpdateBookingStatusModalRef.current?.showOrHiddenModal()
                }
                variant="nolina-info"
                size="sm"
                icon={faToggleOn}
                transform="shrink-2"
                iconAlign="middle"
                className="me-2"
              >
                <span className="d-none d-xl-inline-block ms-1">
                  Change Status
                </span>
              </IconButton>
            </>
          )}
        </Flex>
      </Card.Header>
    </Card>
  );
};

export default BookingCardHeader;
