import IconButton from '@/components/common/IconButton';
import { ITableHeaderComponentProps } from '@/interfaces/Table';
import { useGetDriversQuery } from '@/services/drivers';
import {
  faFileExport,
  faFilter,
  faRefresh,
  faSearch,
  faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableState } from '@tanstack/react-table';
import { FC } from 'react';
import {
  Button,
  Col,
  FormControl,
  InputGroup,
  Row,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

const AllDriversHeader: FC<ITableHeaderComponentProps> = ({
  onQueryChange,
  onShowFilters,
  onStateChange,
  state,
}) => {
  const { refetch } = useGetDriversQuery({
    pageIndex: 0,
    pageSize: 150,
    query: '',
  });
  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">Drivers</h6>
        </Col>
        <Col xs="auto">
          <InputGroup className="position-relative input-search-width">
            <FormControl
              size="sm"
              id="search"
              type="search"
              className="shadow-none"
              placeholder="Search"
              onChange={e => onQueryChange(e.target.value)}
            />
            <Button
              size="sm"
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon={faSearch} className="fs--1" />
            </Button>
          </InputGroup>
        </Col>
        <Col xs="auto">
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            containerPadding={50}
            overlay={
              <Popover id="popover-basic">
                <Popover.Body>
                  <Row className="justify-content-center align-items-center">
                    <Col>
                      <h6 className="mb-0">Commission</h6>
                    </Col>
                    <Col>
                      <FormControl
                        as="select"
                        size="sm"
                        value={(state as any)?.commission ?? '-1'}
                        onChange={e =>
                          onStateChange?.((old: Partial<TableState>) => ({
                            ...old,
                            pagination: {
                              pageSize: old.pagination?.pageSize ?? 15,
                              pageIndex: old.pagination?.pageIndex ?? 0,
                            },
                            commission: e.target.value,
                          }))
                        }
                      >
                        <option value="-1">All</option>
                        <option value="10">10%</option>
                        <option value="8">8%</option>
                        <option value="5">5%</option>
                        <option value="0">0%</option>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center mt-3">
                    <Col>
                      <h6 className="mb-0">Rides completed</h6>
                    </Col>
                    <Col>
                      <FormControl
                        as="select"
                        size="sm"
                        value={(state as any)?.completedRides ?? '-1'}
                        onChange={e =>
                          onStateChange?.((old: Partial<TableState>) => ({
                            ...old,
                            pagination: {
                              pageSize: old.pagination?.pageSize ?? 15,
                              pageIndex: old.pagination?.pageIndex ?? 0,
                            },
                            completedRides: e.target.value,
                          }))
                        }
                      >
                        <option value="-1">All</option>
                        <option value="0">0</option>
                        <option value="lt10">{'less than 10'}</option>
                        <option value="gt10">{'10 and more'}</option>
                        <option value="gt20">{'20 and more'}</option>
                        <option value="gt50">{'50 and more'}</option>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center mt-3">
                    <Col>
                      <h6 className="mb-0">Accept. rate</h6>
                    </Col>
                    <Col>
                      <FormControl
                        as="select"
                        size="sm"
                        value={(state as any)?.acceptanceRate ?? '-1'}
                        onChange={e =>
                          onStateChange?.((old: Partial<TableState>) => ({
                            ...old,
                            pagination: {
                              pageSize: old.pagination?.pageSize ?? 15,
                              pageIndex: old.pagination?.pageIndex ?? 0,
                            },
                            acceptanceRate: e.target.value,
                          }))
                        }
                      >
                        <option value="-1">All</option>
                        <option value="lt10">10% and less</option>
                        <option value="gt10">{'10% - 30%'}</option>
                        <option value="gt30">{'30% - 50%'}</option>
                        <option value="gt50">{'50% and more'}</option>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row className="justify-content-center align-items-center mt-3">
                    <Col>
                      <h6 className="mb-0">Branding</h6>
                    </Col>
                    <Col>
                      <FormControl
                        as="select"
                        size="sm"
                        value={(state as any)?.branding ?? '-1'}
                        onChange={e =>
                          onStateChange?.((old: Partial<TableState>) => ({
                            ...old,
                            pagination: {
                              pageSize: old.pagination?.pageSize ?? 15,
                              pageIndex: old.pagination?.pageIndex ?? 0,
                            },
                            branding: e.target.value,
                          }))
                        }
                      >
                        <option value="all">All</option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </FormControl>
                    </Col>
                  </Row>
                </Popover.Body>
              </Popover>
            }
          >
            <Button
              size="sm"
              variant="outline-secondary"
              className="border-300 hover-border-secondary"
            >
              <FontAwesomeIcon icon={faFilter} className="fs--1" />
              <span className="d-none d-sm-inline-block ms-1">Filter</span>
            </Button>
          </OverlayTrigger>
        </Col>
      </Row>
      <div className="border-bottom border-200 my-3"></div>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        {!!onShowFilters && (
          <>
            <IconButton
              variant="nolina-default"
              size="sm"
              icon={faFilter}
              transform="shrink-4"
              iconAlign="middle"
              onClick={onShowFilters}
              className="d-xl-none"
            >
              <span className="d-none d-sm-inline-block ms-1">Filters</span>
            </IconButton>
            <div
              className="bg-300 mx-3 d-none d-lg-block d-xl-none"
              style={{ width: '1px', height: '29px' }}
            ></div>
          </>
        )}

        <div id="orders-actions">
          <IconButton
            variant="nolina-default"
            size="sm"
            icon={faRefresh}
            transform="shrink-3"
            style={{ marginLeft: '10px' }}
            onClick={refetch}
          >
            <span className="d-none d-sm-inline-block ms-1">Refresh</span>
          </IconButton>
          <a
            href={`${process.env.REACT_APP_API_URI}/export/drivers`}
            rel="noreferrer"
          >
            <IconButton
              variant="nolina-default"
              size="sm"
              icon={faFileExport}
              transform="shrink-3"
              style={{ marginLeft: '10px' }}
            >
              <span className="d-none d-sm-inline-block ms-1">Export</span>
            </IconButton>
          </a>
          <Link to="/drivers/import">
            <IconButton
              variant="nolina-default"
              size="sm"
              icon={faFileImport}
              transform="shrink-3"
              style={{ marginLeft: '10px' }}
            >
              <span className="d-none d-sm-inline-block ms-1">Import</span>
            </IconButton>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AllDriversHeader;
