import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import Flex from '@/components/common/Flex';
import Loader from '@/components/common/Loader';
import Table from '@/components/common/table/Table';
import AllDriversHeader from '@/components/table-headers/drivers/AllDriversHeader';
import WeeklyChallengeDriversHeader from '@/components/table-headers/weeklyChallenge/WeeklyChallengeDriversHeader';
import { IWeeklyChallengeDrivers } from '@/interfaces/IWeeklyChallenges';
import { useGetWeeklyChallengeDriversQuery } from '@/services/weeklyChallenges';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { FC, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

const WeeklyChallengesDriversTable: FC<{ weeklyChallengeId: string }> = ({
  weeklyChallengeId,
}) => {
  const [driverData, setDriverData] = useState<IWeeklyChallengeDrivers[]>([]);
  const [state, setState] = useState<Partial<TableState>>({
    pagination: {
      pageIndex: 0,
      pageSize: 5,
    },
    globalFilter: '',
  });

  const { data, isLoading, isError, error } = useGetWeeklyChallengeDriversQuery(
    [
      weeklyChallengeId,
      {
        ...state.pagination,
        ...(state.sorting?.[0] && {
          sortBy: state.sorting[0].id,
          sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
        }),
      },
    ]
  );

  useEffect(() => {
    if (data) {
      setDriverData(data.data || []);
    }
  }, [data]);

  const columns = useRef<ColumnDef<IWeeklyChallengeDrivers>[]>([
    {
      header: 'First Name\nLast Name',
      footer: props => props.column.id,
      enableSorting: false,
      accessorFn: ({ firstname, lastname, id, avatar }) => (
        <Link to={`/drivers/${id}`}>
          <Flex alignItems="center">
            {avatar && (
              <Avatar
                src={avatar.link}
                alt={`${firstname} Avatar`}
                className="me-2"
                size="2xl"
              />
            )}
            <p className="mb-0">
              <span>{firstname}</span>
              <span>{lastname}</span>
            </p>
          </Flex>
        </Link>
      ),
      id: 'fullname',
      cell: info => info.getValue(),
    },
    {
      header: 'Completed Rides',
      footer: props => props.column.id,
      accessorFn: ({ completedRides }) => completedRides,
      id: 'completedRides',
      cell: info => info.getValue(),
    },
    {
      header: 'Completed referrals',
      footer: props => props.column.id,
      accessorFn: ({ referralCompleted }) => referralCompleted,
      id: 'referralCompleted',
      cell: info => info.getValue(),
    },
    {
      header: 'Success',
      footer: props => props.column.id,
      accessorFn: ({ isSuccess }) => isSuccess ? 'Yes' : 'No',
      id: 'isSuccess',
      cell: info => info.getValue(),
    },
  ]).current;

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} />;
  }

  return (
    <Table
      HeaderComponent={WeeklyChallengeDriversHeader}
      columns={columns}
      state={state}
      onStateChange={setState}
      data={driverData}
      meta={data?.meta}
    />
  );
};

export default WeeklyChallengesDriversTable;
