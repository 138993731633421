import { UserType } from ".";
import { ISelectOption } from "../Select";

export type FilterOption = 'after' | 'before' | '';

export const REGISTRATION_FILTER_OPTIONS: ISelectOption<FilterOption>[] = [
  { value: '', label: 'No value' },
  { value: 'before', label: 'Before' },
  { value: 'after', label: 'After' },
];

export const RIDES_STATUS_OPTIONS: ISelectOption<string>[] = [
  { value: '', label: 'No value' },
  { value: 'driver_cancelled', label: 'Driver cancelled' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'no_drivers_available', label: 'No Drivers available' },
];

export interface IUserListForm {
  name: string;
  user_type: ISelectOption<UserType>;
  registration_date: Date;
  registration_filter_type: ISelectOption<FilterOption>;
  min_rides: number;
  max_rides: number;
  min_requests: number;
  max_requests: number;
  min_points: number;
  max_points: number;
  statusname: ISelectOption<string>;
  referred: boolean
}

export interface IUserList {
  id: string
  name: string
  user_count: number
  created_at: Date
}


export type IUserListIdsFormSend = IUserListIdsForm & {
  type: 'users' | 'ids'
}
export interface IUserListIdsForm {
  name: string;
  ids: string;
}

export interface IUserListFormSend {
  registration_date: string | null;
  user_type: "user" | "driver";
  registration_filter_type: FilterOption;
  statusname: string;
  referred: number;
}