import { ISelectOption } from '@/interfaces/Select';
import IFile from './IFile';

export const IWeeklyChallengeType = ['ride', 'referral'] as const;

export type IWeeklyChallengeType_ = (typeof IWeeklyChallengeType)[number];



export type IWeeklyChallenge = {
  id: string;
  title: string;
  type: IWeeklyChallengeType_;
  archived: boolean;
  startDate: string;
  endDate: string;
  number: number;
  priority: number;
  createdAt: string;
  updatedAt: string;
};

export const WeeklyChallengeTYPE: ISelectOption<IWeeklyChallengeType_>[] = [
  {
    value: 'ride',
    label: 'Ride',
  },
  {
    value: 'referral',
    label: 'Referral',
  },
];


export type IWeeklyChallengeForm = Omit<IWeeklyChallenge, 'id' | 'createdAt' | 'updatedAt' | 'createdAt' | 'updatedAt'> & {
  createdAt: string;
  updatedAt: string;
};

export type IWeeklyChallengeFormCreate = Omit<IWeeklyChallenge, 'id' | 'createdAt' | 'updatedAt' | 'type'> & {
  type: ISelectOption<IWeeklyChallengeType_>;
};

export type IWeeklyChallengeFormUpdate = Omit<IWeeklyChallenge, 'id' | 'createdAt' | 'updatedAt' | 'type' | 'startDate' | 'endDate'> & {
  type: ISelectOption<IWeeklyChallengeType_>;
  startDate: Date;
  endDate: Date;
};

export type IWeeklyChallengeFormCreateSend = Omit<IWeeklyChallengeFormCreate, 'type'> & {
  type: IWeeklyChallengeType_;
}


export type IWeeklyChallengeDrivers = {
  id: string;
  firstname: string;
  lastname: string;
  avatar: IFile;
  isSuccess: boolean;
  completedRides: number;
  referralCompleted: number;
};