import Modal from '@/components/common/Modal';
import Select, { SelectPaginateAsync } from '@/components/common/Select';
import useLoadOptions from '@/hooks/useLoadOptions';
import { IDriverOfferForm } from '@/interfaces/Driver/offer';
import { DriverDocumentVehicleStatusSelect } from '@/interfaces/DriverVehicleDocument';
import { IDrivingPreferences } from '@/interfaces/DrivingPreferences';
import { ICategoryForm } from '@/interfaces/Category';
import { IUserForm } from '@/interfaces/User';
import { useUpdateDriverOffersMutation } from '@/services/drivers/offers';
import { useUpdateDriverPreferencesMutation } from '@/services/drivers/preferences';
import { useUpdateDriverVehicleDocumentMutation } from '@/services/drivers/vehicles';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import {
  ElementRef,
  ForwardRefRenderFunction,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { ToastContentProps, toast } from 'react-toastify';

type UpdateDriverOfferModalProps = {
  idDriver: string;
};

type IModalMethodsParams = {
  offers: ICategoryForm[];
};

type IModalMethods = {
  showOrHiddenModal: (arg: IModalMethodsParams) => void;
};

const UpdateDriverOfferModal: ForwardRefRenderFunction<
  IModalMethods,
  UpdateDriverOfferModalProps
> = ({ idDriver }, ref) => {
  const ModalRef = useRef<ElementRef<typeof Modal>>(null);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IDriverOfferForm>();
  const [updateDriverOffers] = useUpdateDriverOffersMutation();
  const { loadOffersOptions } = useLoadOptions();
  const [args, setArgs] = useState<IModalMethodsParams | undefined>();

  const showOrHiddenModal = (doc?: IModalMethodsParams) => {
    if (doc) setArgs(doc);
    ModalRef.current?.showOrHiddenModal();
  };

  useEffect(() => {
    if (args) {
      reset({
        offers: args.offers,
      });
    }
  }, [args]);

  useImperativeHandle(ref, () => ({
    showOrHiddenModal,
  }));

  const onSubmit: SubmitHandler<IDriverOfferForm> = ({ offers }) => {
    if (args === undefined) return;
    toast.promise(
      updateDriverOffers([
        idDriver,
        {
          offerIds: offers.map(offer => offer.id),
        },
      ]).unwrap(),
      {
        pending: 'Modification des offers du chauffeur en cours...',
        success: {
          render() {
            reset();
            showOrHiddenModal();
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                Les préferences ont bien été modifiée
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<IDriverOfferForm> = () => {};

  return (
    <Modal
      ref={ModalRef}
      title="Mettre à jour les offers du chauffeur."
      requiredLabel
      onSubmit={handleSubmit(onSubmit, onError)}
    >
      <Row className="gx-3">
        <Col lg={6}>
          <Form.Group className="mb-3">
            <Form.Label>
              Offers <span className="text-danger">*</span>
            </Form.Label>
            <Controller
              control={control}
              name="offers"
              render={({ field }) => (
                <SelectPaginateAsync
                  loadOptions={loadOffersOptions}
                  getOptionLabel={option => option.name}
                  getOptionValue={option => option.id}
                  isMulti
                  value={field.value}
                  required
                  onChange={field.onChange}
                />
              )}
            />
            <Form.Control.Feedback type="invalid">
              {errors.offers?.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Modal>
  );
};

export default forwardRef(UpdateDriverOfferModal);
