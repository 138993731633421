import api from '../api';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import { createFormDataRequest } from '@/helpers/utils';
import { WeeklyFleetManager } from '@/interfaces/WeeklyFleetManager';

export const weeklyFleetmanager = api.injectEndpoints({
  endpoints: build => ({
    getWeeklyFleetManager: build.query<
      IPaginateResponse<WeeklyFleetManager>,
      [string, IFilters]
    >({
      query: ([companyId, data]) => {
        const params = createFormDataRequest(data);
        return {
          url: `/companies/${companyId}/weekly-fleet-manager-report`,
          params,
        };
      },
      providesTags: ['WeeklyFleetManager'],
    }),
    sendEmailToManager: build.mutation<
      { message: string },
      { week: number; month: number; year: number; companyId: string }
    >({
      query: ({ companyId, week, month, year }) => ({
        url: `/companies/${companyId}/weekly-fleet-manager-report/send-email`,
        method: 'POST',
        body: {
          week,
          month,
          year,
        },
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetWeeklyFleetManagerQuery, useSendEmailToManagerMutation } =
  weeklyFleetmanager;
