import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import ImageOverview from '@/components/common/ImageOverview';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import UnknownBadge from '@/components/common/UnknownBadge';
import Table from '@/components/common/table/Table';
import AreYouSurToContinueModal from '@/components/modals/AreYouSurToContinueModal';
import {
  getColorFromIsActive,
  getColorFromOfferStatus,
  getColorFromRideStatus,
  getOfferStatus,
  getOfferStatusTimeStamp,
  getReelIsActiveName,
  getReelRideStatus,
} from '@/helpers/UserHelpers';
import { truncate } from '@/helpers/utils';
import { IFilters } from '@/interfaces/Fetch';
import { ICategoryForm } from '@/interfaces/Category';
import {
  useDestroyCategoryMutation,
  useUpdateCategoryMutation,
} from '@/services/categories';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ColumnDef, TableState } from '@tanstack/react-table';
import { DateTime } from 'luxon';
import { ElementRef, FC, useCallback, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast, ToastContentProps } from 'react-toastify';
import { useGetOffersQuery } from '@/services/offers';
import { IOffer } from '@/interfaces/IOffer';
import {
  getColorFromDriverNotificationStatusColor,
  getDriverNotificationStatusName,
} from '@/helpers/DriverHelpers';
import AllOffersHeader from '@/components/table-headers/offers/AllOffersHeader';
import dayjs from 'dayjs';

const OffersView: FC = () => {
  const DeleteModalRef =
    useRef<ElementRef<typeof AreYouSurToContinueModal>>(null);
  const [state, setState] = useState<Partial<TableState> & IFilters>({
    pagination: {
      pageIndex: 0,
      pageSize: 150,
    },
    globalFilter: '',
  });

  const [destroyOfferAsync] = useDestroyCategoryMutation();

  const result = useGetOffersQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const { data, isLoading, isError, error } = useGetOffersQuery({
    ...state.pagination,
    query: state.globalFilter,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const [updateOffer, { isLoading: isUpdateOfferLoading }] =
    useUpdateCategoryMutation();

  const handleActiveToggle = async (offerId: string, isActive: boolean) => {
    try {
      const offerToUpdate: Partial<ICategoryForm> = {
        isActive: !isActive,
      };
      await updateOffer([offerId, offerToUpdate]);
    } catch (error) {
      console.error('Error toggling archive status:', error);
    }
  };

  const columns = useRef<ColumnDef<IOffer>[]>([
    {
      header: 'Timestamp Offer Received',
      footer: props => props.column.id,
      accessorFn: ({ driverriderequest: { offerReceivedAt } }) => {
        if (!offerReceivedAt) return 'N/A';
        return (
          <div>{dayjs(offerReceivedAt).format('DD/MM/YYYY HH:mm:ss')}</div>
        );
      },
      id: 'offerReceivedAt',
      cell: info => info.getValue(),
    },
    {
      header: 'Offer Id',
      enableSorting: false,
      footer: props => props.column.id,
      accessorFn: ({ driverriderequest: { offerId } }) => truncate(offerId, 10),
      id: 'actions',
      cell: info => info.getValue(),
    },
    {
      header: 'Offer Status',
      footer: props => props.column.id,
      accessorFn: ({
        driverriderequest: { offerStatus, requestTimoutedAt },
      }) => {
        const offerStatus_ = requestTimoutedAt ? 'timouted' : offerStatus;

        const colorFromRequestTime =
          getColorFromDriverNotificationStatusColor(offerStatus_);
        return (
          <SoftBadge
            bg={colorFromRequestTime}
            className={'my-2 text-xl-center'}
          >
            {getDriverNotificationStatusName(offerStatus)}
          </SoftBadge>
        );
      },
      id: 'offzerStatus',
      cell: info => info.getValue(),
    },
    {
      header: 'Request ID',
      footer: props => props.column.id,
      accessorFn: ({ requestid }) => (
        <Link to={`/rides/${requestid}`}>{truncate(requestid, 10)}</Link>
      ),
      id: 'request',
      cell: info => info.getValue(),
    },
    {
      header: 'Driver ID',
      footer: props => props.column.id,
      // accessorFn: ({ driverid }) => driverid,
      accessorFn: ({ driverid }) => (
        <Link to={`/drivers/${driverid}`}>{truncate(driverid, 10)}</Link>
      ),
      id: 'driverid',
      cell: info => info.getValue(),
    },
    {
      header: 'Request Status',
      footer: props => props.column.id,
      accessorFn: ({ driverriderequest: { requestStatus } }) => {
        return (
          <SoftBadge
            bg={getColorFromRideStatus(requestStatus)}
            className={'my-2 text-xl-center'}
          >
            {getReelRideStatus(requestStatus)}
          </SoftBadge>
        );
      },
      id: 'isActive',
      cell: info => info.getValue(),
    },
    {
      header: 'DriverPrice',
      footer: props => props.column.id,
      accessorFn: ({ driverriderequest: { driverPrice } }) => driverPrice,
      id: 'driverPrice',
      cell: info => info.getValue(),
    },
    {
      header: 'ETA',
      footer: props => props.column.id,
      accessorFn: ({ driverriderequest: { ETA } }) => ETA,
      id: 'ETA',
      cell: info => info.getValue(),
    },
    {
      header: 'Distance Pick Up',
      footer: props => props.column.id,
      accessorFn: ({ driverriderequest: { distancePickUp } }) => distancePickUp,
      id: 'DistancePU',
      cell: info => info.getValue(),
    },
    {
      header: 'Timestamp Offer Accepted',
      footer: props => props.column.id,
      accessorFn: ({
        driverriderequest: {
          requestCancelledAt,
          requestAcceptedAt,
          requestRejectedAt,
        },
      }) => {
        const offerStatus = getOfferStatusTimeStamp(
          requestCancelledAt,
          requestAcceptedAt,
          requestRejectedAt
        );
        if (offerStatus === 'N/A') {
          return (
            <SoftBadge bg={'info'} className={'my-2 text-xl-center'}>
              N/A
            </SoftBadge>
          );
        }
        return <div>{dayjs(offerStatus).format('DD/MM/YYYY HH:mm:ss')}</div>;
      },
      id: 'createdAt',
      cell: info => info.getValue(),
    },
  ]).current;

  const onDelete = useCallback((id: string) => {
    toast.promise(destroyOfferAsync(id).unwrap(), {
      pending: 'Suppression du offer en cours ...',
      success: {
        render() {
          DeleteModalRef.current?.showOrHiddenModal();
          return (
            <p className="mb-0 text-center">Le offer à bien été supprimé 🤩</p>
          );
        },
      },
      error: {
        render({ data }: ToastContentProps<FetchBaseQueryError>) {
          return 'Une erreur est survenue';
        },
      },
    });
  }, []);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <div>
      <AreYouSurToContinueModal
        ref={DeleteModalRef}
        onContinue={onDelete}
        title="Souhaitez-vous vraiment supprimé le offer qui a pour id : {{id}} ?"
      />
      <Table
        HeaderComponent={AllOffersHeader}
        columns={columns}
        paddingRows="md"
        state={state}
        onStateChange={setState}
        data={data?.data}
        meta={data?.meta}
      />
    </div>
  );
};

export default OffersView;
