import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import ICategory, { ICategoryForm } from '@/interfaces/Category';
import { createFormDataRequest } from '../helpers/utils';
import api from './api';

export const categoriesApi = api.injectEndpoints({
  endpoints: build => ({
    getCategories: build.query<IPaginateResponse<ICategory>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/offers',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
              ...result.data.map(({ id }) => ({
                type: 'Categories' as const,
                id,
              })),
              { type: 'Categories', id: 'LIST', ...filters },
            ]
          : [],
    }),
    getCategory: build.query<ICategory, string>({
      query: id => `/offers/${id}`,
      providesTags: (_offer, _err) => [{ type: 'Offers', id: 'LIST' }],
    }),
    storeCategory: build.mutation<ICategory, ICategoryForm>({
      query: data => {
        const body = createFormDataRequest(data);
        return {
          url: '/offers',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
    }),
    updateCategory: build.mutation<ICategory, [string, Partial<ICategoryForm>]>({
      query: ([id, data]) => {
        const body = createFormDataRequest(data);
        return {
          url: `/offers/${id}`,
          method: 'PATCH',
          body,
        };
      },
      invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
    }),
    destroyCategory: build.mutation<void, string>({
      query: id => ({
        url: `/offers/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Categories', id: 'LIST' }],
    }),
  }),
  overrideExisting: true,
});

export const {
  useGetCategoriesQuery,
  useLazyGetCategoriesQuery,
  useGetCategoryQuery,
  useStoreCategoryMutation,
  useUpdateCategoryMutation,
  useDestroyCategoryMutation,

} = categoriesApi;

// export const {
//   use,
//   useStoreOfferMutation,
//   useLazyGetOffersQuery,
//   useDestroyOfferMutation,
//   useGetOfferQuery,
//   useUpdateOfferMutation,
// } = categoriesApi;
