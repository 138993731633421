import { PropsValue, MultiValue, SingleValue } from 'react-select';
import Vehicle from './Vehicle';
import { ISelectOption } from './Select';
import ICategory from './Category';

export const IDriverVehicleStatus_ = [
  'maintenance',
  'active',
  'inactive',
  'archived',
  'not_approved',
] as const;

export type IDriverVehicleStatus = (typeof IDriverVehicleStatus_)[number];

export type DriverVehicleFormUpdate = {
  default: boolean;
  status: ISelectOption<IDriverVehicleStatus>;
  color: string;
  colorLabel: string;
  licensePlate: string;
  year: number;
  availableOfferIds: MultiValue<ICategory>;
  vehicleId: SingleValue<Vehicle>;
};

export type DriverVehicleFormUpdateSend = {
  availableOfferIds: string[];
  vehicleId: string;
  status: IDriverVehicleStatus;
} & Omit<DriverVehicleFormUpdate, 'availableOfferIds' | 'vehicleId' | 'status'>;

interface DriverVehicle {
  id: string;
  driverProfileId: string;
  vehicleId: string;
  color: string;
  colorLabel: string;
  licensePlate: string;
  vehicle: Vehicle;
  offers: ICategory[];
  year: number;
  default: boolean;
  status: IDriverVehicleStatus;
}

export interface DriverVehicleFormAdd
  extends Omit<
    DriverVehicle,
    'driverProfileId' | 'status' | 'default' | 'id' | 'vehicle' | 'vehicleId'
  > {
  vehicleId: SingleValue<Vehicle>;
  availableOfferIds: MultiValue<ICategory>;
}

export interface DriverVehicleFormAddCSV
  extends Omit<
    DriverVehicle,
    'driverProfileId' | 'status' | 'default' | 'id' | 'vehicle' | 'vehicleId'
  > {
  vehicleId: SingleValue<Vehicle>;
}

export interface DriverVehicleFormSend
  extends Omit<DriverVehicleFormAdd, 'vehicleId' | 'availableOfferIds'> {
  vehicleId: string;
  availableOfferIds: string[];
}

export interface DriverVehicleFormSendCSV
  extends Omit<DriverVehicleFormAddCSV, 'vehicleId'> {
  vehicleId: string;
  availableOfferIds: string[];
  default: boolean;
}

export default DriverVehicle;
