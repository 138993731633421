import { SelectPaginateAsync } from '@/components/common/Select';
import useLoadOptions from '@/hooks/useLoadOptions';
import Driver from '@/interfaces/Driver';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';

interface AssignDriverToBookingModalProps {
  bookingId: string;
  onAssign: (bookingId: string, newStatus: string) => void;
}

export interface AssingDriverToBookingModalHandle {
  showOrHiddenModal: () => void;
}

export const AssignDriverToBookingModal = forwardRef<
  AssingDriverToBookingModalHandle,
  AssignDriverToBookingModalProps
>(({ bookingId, onAssign }, ref) => {
  const [show, setShow] = useState(false);
  const [driverValue, setDriverValue] = useState<Driver | null>(null);
  const { loadDriversList } = useLoadOptions();

  const {
    control,
    formState: { errors },
  } = useForm<{ bookingId: string; driver: Driver }>();

  const handleUpdate = () => {
    onAssign(bookingId, driverValue?.id!);
    setShow(false);
  };

  useImperativeHandle(ref, () => ({
    showOrHiddenModal: () => setShow(!show),
  }));

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Assign Driver</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Col lg={12}>
            <Form.Group className="mb-3">
              <Form.Label>
                Driver <span className="text-danger">*</span>
              </Form.Label>
              <Controller
                control={control}
                name="driver"
                render={({ field }) => (
                  <SelectPaginateAsync
                    loadOptions={loadDriversList}
                    getOptionLabel={option =>
                      option.user.firstname + ' ' + option.user.lastname
                    }
                    getOptionValue={option => option.id}
                    value={driverValue}
                    onChange={e => {
                      field.onChange(e);
                      setDriverValue(e);
                    }}
                  />
                )}
              />
              <Form.Control.Feedback type="invalid">
                {errors.driver?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
        <Button variant="primary" type="submit" onClick={handleUpdate}>
          Assign Driver
        </Button>
      </Modal.Footer>
    </Modal>
  );
});
