import CardHeader from '@/components/card-headers/CardHeader';
import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import Select from '@/components/common/Select';
import { EMAIL_REGEX } from '@/constants/regexs';
import getDifferenceBetweenTwoObjects from '@/helpers/FormHelpers';

import { ACCESS_TYPES, GENDERS, IUserForm, USER_ROLES } from '@/interfaces/User';
import { selectCurrentUser } from '@/services/slices/authSlice';
import { useGetUserQuery, useUpdateUserMutation } from '@/services/users';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import classNames from 'classnames';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { FC, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import {
  Controller,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ToastContentProps, toast } from 'react-toastify';

const UserEditCard: FC<any> = ({ id }) => {
  const { data, isLoading, isError, error } = useGetUserQuery(id);
  const user = useSelector(selectCurrentUser);
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Partial<IUserForm>>();
  const [updateUserAsync] = useUpdateUserMutation();
  const navigation = useNavigate();

  useEffect(() => {
    data &&
      reset({
        ...data,
        gender: GENDERS.find(gender => gender.value === data.gender),
        role: USER_ROLES.find(role => role.value === data.role),
        avatar: null,
        hasAccessReservePlatform: ACCESS_TYPES.find(access => access.value === data.hasAccessReservePlatform),
      });
  }, [data]);

  const onSubmit: SubmitHandler<Partial<IUserForm>> = ({
    role,
    gender,
    avatar,
    hasAccessReservePlatform,
    ...newData
  }) => {
    const result = getDifferenceBetweenTwoObjects<Partial<IUserForm>>(
      data,
      newData
    );
    toast.promise(
      updateUserAsync([
        id,
        {
          ...result,
          avatar,
          role: role?.value,
          gender: gender?.value,
          hasAccessReservePlatform: hasAccessReservePlatform?.value,
        },
      ]).unwrap(),
      {
        pending: "Modification de l'utilisateur en cours ...",
        success: {
          render() {
            navigation(-1);
            return (
              <p style={{ marginBottom: 0, textAlign: 'center' }}>
                L'utilisateur à bien été modifié 🤩
              </p>
            );
          },
        },
        error: {
          render({ data }: ToastContentProps<FetchBaseQueryError>) {
            if (data?.status === 422) {
              return 'Les champs que vous avez remplis semblent être incorrects.';
            }

            return 'Une erreur est survenue';
          },
        },
      }
    );
  };

  const onError: SubmitErrorHandler<Partial<IUserForm>> = () =>
    toast.error('INVALID_FORM_MESSAGE');

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit, onError)}>
      <Card>
        <CardHeader title="Modification de l'utilisateur" />
        <Card.Body className="bg-light">
          <Row className="gx-3">
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Prénom <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Remi"
                  type="text"
                  {...register('firstname', {
                    required: {
                      value: true,
                      message: 'INVALID_FIRSTNAME_MESSAGE',
                    },
                    minLength: {
                      value: 2,
                      message: 'INVALID_MINIMUM_CARACTERE_MESSAGE',
                    },
                    maxLength: {
                      value: 255,
                      message: 'INVALID_MAXIMUM_CARACTERE_MESSAGE',
                    },
                  })}
                  isInvalid={!!errors.firstname}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstname?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Avatar :</Form.Label>
                <Form.Control type="file" {...register('avatar')} />
                <Form.Control.Feedback type="invalid">
                  {errors.avatar?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Nom de famille <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : Dupont"
                  type="text"
                  {...register('lastname', {
                    required: {
                      value: true,
                      message: 'INVALID_LASTNAME_MESSAGE',
                    },
                    minLength: {
                      value: 2,
                      message: 'INVALID_MINIMUM_CARACTERE_MESSAGE',
                    },
                    maxLength: {
                      value: 255,
                      message: 'INVALID_MAXIMUM_CARACTERE_MESSAGE',
                    },
                  })}
                  isInvalid={!!errors.lastname}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastname?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Email <span className="text-danger">*</span> :
                </Form.Label>
                <Form.Control
                  placeholder="ex : remi@groupe-pi.com"
                  type="email"
                  {...register('email', {
                    required: 'INVALID_EMAIL_MESSAGE',
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'INVALID_EMAIL2_MESSAGE',
                    },
                  })}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Numéro de téléphone :</Form.Label>
                <Form.Control
                  placeholder="ex : +33 6 34 83 55 32"
                  type="text"
                  {...register('phone', {
                    validate: value => {
                      if (value && !isValidPhoneNumber(value, 'FR')) {
                        return 'INVALID_PHONE2_MESSAGE';
                      }
                    },
                  })}
                  isInvalid={!!errors.phone}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.phone?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Genre :</Form.Label>
                <Controller
                  control={control}
                  name="gender"
                  render={({ field }) => (
                    <Select
                      options={GENDERS}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={classNames({
                    'd-block': !!errors.gender,
                  })}
                >
                  {errors.gender?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Role <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="role"
                  render={({ field }) => (
                    <Select
                      isDisabled={user?.id.toString() === id}
                      options={USER_ROLES}
                      value={field.value}
                      closeMenuOnSelect
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={classNames({
                    'd-block': !!errors.role,
                  })}
                >
                  {errors.role?.message}
                </Form.Control.Feedback>
                {user?.id.toString() === id && (
                  <Form.Text>
                    Vous ne pouvez pas modifier votre propre rôle.
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Access plateforme de réservation <span className="text-danger">*</span> :
                </Form.Label>
                <Controller
                  control={control}
                  name="hasAccessReservePlatform"
                  render={({ field }) => (
                    <Select
                      options={ACCESS_TYPES}
                      value={field.value}
                      closeMenuOnSelect
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback
                  type="invalid"
                  className={classNames({
                    'd-block': !!errors.hasAccessReservePlatform,
                  })}
                >
                  {errors.hasAccessReservePlatform?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Button
            type="submit"
            variant="success"
            disabled={isLoading}
            className="mt-3"
          >
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UserEditCard;
