import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';

import { createFormDataRequest } from '../helpers/utils';
import api from './api';
import { IOffer } from '@/interfaces/IOffer';

export const offersApi = api.injectEndpoints({
  endpoints: build => ({
    getOffers: build.query<IPaginateResponse<IOffer>, IFilters>({
      query: data => {
        const params = createFormDataRequest(data);
        return {
          url: '/driverRideRequests',
          params,
        };
      },
      providesTags: (result, _error, filters) =>
        result
          ? [
            ...result.data.map(({ requestid }) => ({
              type: 'Offers' as const,
              id: requestid,
            })),
            { type: 'Offers', id: 'LIST', ...filters },
          ]
          : [],
    }),

    getDriverOffersCSV: build.query<any, void>({
      query: data => {
        return {
          url: '/driverRideRequests/CSVList',
          responseHandler: async (response) => window.location.assign(window.URL.createObjectURL(await response.blob())),
        };
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useLazyGetOffersQuery,
  useGetOffersQuery,
  useLazyGetDriverOffersCSVQuery,
} = offersApi;
