import React, { FC, useEffect, useMemo } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Controller, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DateTime } from 'luxon';
import Select, { SelectPaginateAsync } from '@/components/common/Select';
import Loader from '@/components/common/Loader';
import ErrorComponent from '@/components/common/ErrorComponent';
import useLoadOptions from '@/hooks/useLoadOptions';
import { useGetDriverQuery, useUpdateDriverMutation } from '@/services/drivers';
import {
  ComissionRateOptions,
  DRIVER_EXPERIENCE_YEARS_TYPES_SELECT,
  DRIVER_PRIMARY_SHIFT_TYPES_SELECT,
  IDriverFormSendUpdate,
  IDriverFormUpdate,
  WEEKLY_DRIVING_HOURS_TYPES_SELECT,
} from '@/interfaces/Driver';

type UpdateUserCardProps = {
  id: string;
  callBack?: (driver: any) => void;
};

const RATTACHEMENT_OPTIONS = [
  { value: true, label: 'Oui' },
  { value: false, label: 'Non' },
];

const UpdateDriverCard: FC<UpdateUserCardProps> = ({ id, callBack }) => {
  const { data, isLoading, isError, error } = useGetDriverQuery(id);
  const [updateDriverAsync] = useUpdateDriverMutation();
  const navigate = useNavigate();
  const { loadCompaniesOptions } = useLoadOptions();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    register,
    setValue,
  } = useForm<IDriverFormUpdate>({
    defaultValues: {
      commissionRate: { value: 10, label: '10%' },
      commissionStartDate: '',
      commissionEndDate: '',
      branding: null,
    },
  });

  const watchCommissionRate = useWatch({
    control,
    name: 'commissionRate',
  });

  useEffect(() => {
    if (
      data &&
      watchCommissionRate?.value !== data.commissionRate &&
      watchCommissionRate?.value !== 10
    ) {
      const today = DateTime.now().toFormat("yyyy-MM-dd'T'HH:mm");
      const oneMonthLater = DateTime.now()
        .plus({ months: 1 })
        .toFormat("yyyy-MM-dd'T'HH:mm");
      setValue('commissionStartDate', today);
      setValue('commissionEndDate', oneMonthLater);
    }
  }, [watchCommissionRate, setValue, data]);

  useMemo(() => {
    if (callBack && data) callBack(data);
  }, [data, callBack]);

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        driverExperienceYears: DRIVER_EXPERIENCE_YEARS_TYPES_SELECT.find(
          option => option.value === data.driverExperienceYears
        ),
        weeklyDrivingHours: WEEKLY_DRIVING_HOURS_TYPES_SELECT.find(
          option => option.value === data.weeklyDrivingHours
        ),
        primaryShift: DRIVER_PRIMARY_SHIFT_TYPES_SELECT.find(
          option => option.value === data.primaryShift
        ),
        commissionRate: ComissionRateOptions.find(
          option => option.value === data.commissionRate
        ),
        commissionStartDate: data.commissionStartDate
          ? DateTime.fromISO(data.commissionStartDate).toFormat(
              "yyyy-MM-dd'T'HH:mm"
            )
          : '',
        commissionEndDate: data.commissionEndDate
          ? DateTime.fromISO(data.commissionEndDate).toFormat(
              "yyyy-MM-dd'T'HH:mm"
            )
          : '',
        branding: data.branding
          ? DateTime.fromISO(data.branding).toFormat('yyyy-MM-dd')
          : null,
        isCompanyRattachement: data.isCompanyRattachement,
      });
    }
  }, [data, reset]);

  const onSubmit: SubmitHandler<IDriverFormUpdate> = async formData => {
    const payload: IDriverFormSendUpdate = {
      companyId: formData.company?.id || undefined,
      referralCode: formData.referralCode || '',
      commissionRate: formData.commissionRate?.value as any,
      commissionStartDate: formData.commissionStartDate || null,
      commissionEndDate: formData.commissionEndDate || null,
      primaryShift: formData.primaryShift?.value || null,
      driverExperienceYears: formData.driverExperienceYears?.value || null,
      weeklyDrivingHours: formData.weeklyDrivingHours?.value || null,
      branding: formData.branding || null,
      isCompanyRattachement: formData.isCompanyRattachement,
    };

    try {
      await toast.promise(updateDriverAsync([id, payload]).unwrap(), {
        pending: 'Modification du chauffeur en cours...',
        success: {
          render() {
            navigate(-1);
            return <p>La modification a bien été effectuée</p>;
          },
        },
        error: 'Une erreur est survenue lors de la modification.',
      });
    } catch (err) {
      console.error('Update Error:', err);
    }
  };

  if (isLoading) return <Loader absolute />;
  if (isError) return <ErrorComponent error={error} absolute returnButton />;

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <Card.Body>
          <Row>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Driver Experience Years</Form.Label>
                <Controller
                  name="driverExperienceYears"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={DRIVER_EXPERIENCE_YEARS_TYPES_SELECT}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Weekly Driving Hours</Form.Label>
                <Controller
                  name="weeklyDrivingHours"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={WEEKLY_DRIVING_HOURS_TYPES_SELECT}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Primary Shift</Form.Label>
                <Controller
                  name="primaryShift"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={DRIVER_PRIMARY_SHIFT_TYPES_SELECT}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  Company <span className="text-danger">*</span>
                </Form.Label>
                <Controller
                  control={control}
                  name="company"
                  render={({ field }) => (
                    <SelectPaginateAsync
                      loadOptions={loadCompaniesOptions}
                      getOptionLabel={option => option.name}
                      getOptionValue={option => option.id}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.company?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Commission Rate</Form.Label>
                <Controller
                  name="commissionRate"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={ComissionRateOptions}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Commission Start Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  {...register('commissionStartDate')}
                  disabled={watchCommissionRate?.value === 10}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Commission End Date</Form.Label>
                <Form.Control
                  type="datetime-local"
                  {...register('commissionEndDate')}
                  disabled={watchCommissionRate?.value === 10}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Rattachement</Form.Label>
                <Controller
                  name="isCompanyRattachement"
                  control={control}
                  render={({ field }) => (
                    <Select
                      options={RATTACHEMENT_OPTIONS}
                      value={RATTACHEMENT_OPTIONS.find(
                        option => option.value === field.value
                      )}
                      onChange={selected => field.onChange(selected?.value)}
                    />
                  )}
                />
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group>
                <Form.Label>Branding Date</Form.Label>
                <Form.Control type="date" {...register('branding')} />
              </Form.Group>
            </Col>
          </Row>
          <Button type="submit" className="mt-5">
            Confirmer
          </Button>
        </Card.Body>
      </Card>
    </Form>
  );
};

export default UpdateDriverCard;
