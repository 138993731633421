import Company from '@/interfaces/Company';
import ICoupon from '@/interfaces/Coupon';
import { IDrivingPreferences } from '@/interfaces/DrivingPreferences';
import { IFilters, IPaginateResponse } from '@/interfaces/Fetch';
import ICategory from '@/interfaces/Category';
import IPartnership from '@/interfaces/Partnership/Partnership';
import { PlaceAutocompleteResult } from '@/interfaces/PlaceAutocomplete';
import { IUserList } from '@/interfaces/User/UserList';
import Vehicle from '@/interfaces/Vehicle';
import { useLazyGetCategoriesQuery } from '@/services/categories';
import { useLazyGetCompaniesQuery } from '@/services/companies';
import { useLazyGetCouponsQuery } from '@/services/coupons';
import { useLazyGetDrivingPreferencesQuery } from '@/services/drivingPreferences';
import { useLazyGetPlacesAutocompleteQuery } from '@/services/google/placeAutocomplete';

import { useLazyGetPartnershipsQuery } from '@/services/partnerships/partnerships';
import { useLazyGetUserListQuery } from '@/services/userList';
import { useLazyGetVehiclesQuery } from '@/services/vehicles';
import { debounce } from 'lodash';
import { GroupBase, OptionsOrGroups } from 'react-select';
import Driver, { IDriverFilters } from '@/interfaces/Driver';
import { useLazyGetDriversQuery } from '@/services/drivers';

type IProps =
  | {
      onCallback?: (name: string, response: IPaginateResponse<any>) => void;
    }
  | undefined;

const useLoadOptions = ({ onCallback }: IProps = {}) => {
  const [getPlacesAutocompleteLazy] = useLazyGetPlacesAutocompleteQuery();
  const [getOffersLazy] = useLazyGetCategoriesQuery();
  const [getCompaniesLazy] = useLazyGetCompaniesQuery();
  const [getPartnershipsLazy] = useLazyGetPartnershipsQuery();
  const [getDrivingPreferencesLazy] = useLazyGetDrivingPreferencesQuery();
  const [getVehiclesLazy] = useLazyGetVehiclesQuery();
  const [getCominCouponLazy] = useLazyGetCouponsQuery();
  const [getUserListLazy] = useLazyGetUserListQuery();
  const [getDriversListLazy] = useLazyGetDriversQuery()

  const loadPlacesAutocomplete = async (
    query: string,
    callback: (options: PlaceAutocompleteResult[]) => void
  ) => {
    const response = await getPlacesAutocompleteLazy({
      query,
    }).unwrap();
    callback(response);
    return response;
  };

  const loadDrivingPreferencesLazy = async (
    query: string,
    _: OptionsOrGroups<IDrivingPreferences, GroupBase<IDrivingPreferences>>,
    filters?: IFilters
  ) => {
    const response = await getDrivingPreferencesLazy({
      query,
      ...filters,
    }).unwrap();

    return {
      options: response.data,
      hasMore: response.meta.currentPage !== response.meta.lastPage,
      additional: {
        ...filters,
        pageIndex: response.meta.currentPage,
      },
    };
  };

  const loadOffersOptions = async (
    query: string,
    _: OptionsOrGroups<ICategory, GroupBase<ICategory>>,
    filters?: IFilters
  ) => {
    const response = await getOffersLazy({
      query,
      ...filters,
    }).unwrap();

    return {
      options: response.data,
      hasMore: response.meta.currentPage !== response.meta.lastPage,
      additional: {
        ...filters,
        pageIndex: response.meta.currentPage,
      },
    };
  };

  const loadVehicles = async (
    query: string,
    _: OptionsOrGroups<Vehicle, GroupBase<Vehicle>>,
    filters?: IFilters
  ) => {
    const response = await getVehiclesLazy({
      query,
      ...filters,
    }).unwrap();

    return {
      options: response.data,
      hasMore: response.meta.currentPage !== response.meta.lastPage,
      additional: {
        ...filters,
        pageIndex: response.meta.currentPage,
      },
    };
  };

  const loadUserList = async (
    query: string,
    _: OptionsOrGroups<IUserList, GroupBase<IUserList>>,
    filters?: IFilters
  ) => {
    const response = await getUserListLazy({
      query,
      ...filters,
    }).unwrap();

    return {
      options: response.data,
      hasMore: response.meta.currentPage !== response.meta.lastPage,
      additional: {
        ...filters,
        pageIndex: response.meta.currentPage,
      },
    };
  };

  const loadDriversList = async (
    query: string,
    _: OptionsOrGroups<Driver, GroupBase<Driver>>,
    filters?: IDriverFilters
  ) => {
    const response = await getDriversListLazy({
      query,
      ...filters,
    }).unwrap();

    return {
      options: response.data,
      hasMore: response.meta.currentPage !== response.meta.lastPage,
      additional: {
        ...filters,
        pageIndex: response.meta.currentPage,
      },
    };
  }

  const loadCominCoupon = async (
    query: string,
    _: OptionsOrGroups<ICoupon, GroupBase<ICoupon>>,
    filters?: IFilters
  ) => {
    console.log(filters)
    const response = await getCominCouponLazy({ query, ...filters }).unwrap();

    return {
      options: response.data,
      hasMore: response.meta.currentPage !== response.meta.lastPage,
      additional: {
        ...filters,
        pageIndex: response.meta.currentPage,
      },
    };
  };

  const loadPartnerships = async (
    query: string,
    _: OptionsOrGroups<IPartnership, GroupBase<IPartnership>>,
    filters?: IFilters
  ) => {
    const response = await getPartnershipsLazy({
      query,
      ...filters,
    }).unwrap();

    return {
      options: response.data,
      hasMore: response.meta.currentPage !== response.meta.lastPage,
      additional: {
        ...filters,
        pageIndex: response.meta.currentPage,
      },
    };
  };

  const loadCompaniesOptions = async (
    query: string,
    _: OptionsOrGroups<Company, GroupBase<Company>>
  ) => {
    const response = await getCompaniesLazy({
      query,
    }).unwrap();

    return {
      options: response.data,
      hasMore: response.meta.currentPage !== response.meta.lastPage,
      additional: {
        pageIndex: response.meta.currentPage,
      },
    };
  };

  return {
    loadPlacesAutocomplete: debounce(loadPlacesAutocomplete, 500),
    loadOffersOptions,
    loadCompaniesOptions,
    loadVehicles,
    loadPartnerships,
    loadDrivingPreferencesLazy,
    loadCominCoupon,
    loadUserList,
    loadDriversList,
  };
};

export default useLoadOptions;
