import ErrorComponent from '@/components/common/ErrorComponent';
import Loader from '@/components/common/Loader';
import { ICardDetailsProps } from '@/interfaces/Card';
import { DateTime } from 'luxon';
import { FC, useEffect } from 'react';
import { Badge, Card, Col, Row, Table } from 'react-bootstrap';
import Company from '@/interfaces/Company';
import { useGetCompanyQuery } from '@/services/companies';
import { Link } from 'react-router-dom';
import { GOOGLEMAPSWEBLINK } from '@/constants/places';
import SoftBadge from '@/components/common/SoftBadge';
import { getColorFromCompanyStatus } from '@/helpers/CompanyHelper';
import UnknownBadge from '@/components/common/UnknownBadge';
import classNames from 'classnames';

const CompanyDetailsCard: FC<ICardDetailsProps<Company>> = ({
  id,
  callBack,
}) => {
  const { data, isLoading, isError, error } = useGetCompanyQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Row className="g-3">
      <Col xs={12}>
        <Card className="font-sans-serif">
          <Card.Body>
            <Row className="gx-3">
              <Col xs={12} md={6} className="mb-3 mb-md-0">
                <Table borderless className="fs--1 fw-medium mb-0">
                  <tbody>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        Id:
                      </td>
                      <td className="p-1 text-600">{data.id}</td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        Address:
                      </td>
                      <td className="p-1 text-600">
                        <Link
                          to={`${GOOGLEMAPSWEBLINK}${data.address.placeId}`}
                        >
                          {data.address.address}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        Status:
                      </td>

                      <td className="p-1 text-600">
                        <SoftBadge
                          bg={getColorFromCompanyStatus(data.status)}
                          className={'text-xl-center'}
                        >
                          {data.status}
                        </SoftBadge>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        Phone:
                      </td>
                      <td className="p-1 text-600">
                        <Link to={`tel:${data.phone}`}>{data.phone}</Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        Name:
                      </td>
                      <td className="p-1 text-600 fw-bold">{data.name}</td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        Rattachement?:
                      </td>
                      <td className="p-1 text-600 fw-bold">
                        {data.fleetCompaniesStatus ? (
                          <SoftBadge bg="success" className="my-2 text-xl-center">
                            Oui
                          </SoftBadge>
                        ) : (
                          <SoftBadge bg="danger" className="my-2 text-xl-center">
                            Non
                          </SoftBadge>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        TVA Redevable?:
                      </td>
                      <td className="p-1 text-600 fw-bold">
                        {data.tvaRedevable ? (
                          <SoftBadge bg="success" className="my-2 text-xl-center">
                            Oui
                          </SoftBadge>
                        ) : (
                          <SoftBadge bg="danger" className="my-2 text-xl-center">
                            Non
                          </SoftBadge>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        bankAccountCountry:
                      </td>
                      <td className="p-1 text-600">
                        <SoftBadge bg={'info'} className={'text-xl-center'}>
                          {data.bankAccountCountry}
                        </SoftBadge>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '40%' }}>
                        bankAccountCurrency:
                      </td>
                      <td className="p-1 text-600">
                        <span
                          className={classNames(
                            'badge border link-secondary text-decoration-none'
                          )}
                        >
                          {data.bankAccountCurrency}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '40%' }}>
                        bankAccountHolderName:
                      </td>
                      <td className="p-1 text-600">
                        {data.bankAccountHolderName}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '40%' }}>
                        bankAccountNumber:
                      </td>
                      <td className="p-1 text-600">{data.bankAccountNumber}</td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '40%' }}>
                        businessType:
                      </td>
                      <td className="p-1 text-600">
                        <Badge bg="info" className="text-white fs--2">
                          {data.businessType}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '40%' }}>
                        chargesEnabled:
                      </td>

                      <td className="p-1 text-600">
                        <Badge bg="200" className="text-secondary fs--2">
                          {data.chargesEnabled ? 'YES' : 'NO'}
                        </Badge>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '40%' }}>
                        defaultCurrency:
                      </td>
                      <td className="p-1 text-600">
                        <span
                          className={classNames(
                            'badge border link-secondary text-decoration-none'
                          )}
                        >
                          {data.defaultCurrency}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '40%' }}>
                        detailsSubmitted:
                      </td>
                      <td className="p-1 text-600">
                        <Badge bg="200" className="text-secondary fs--2">
                          {data.detailsSubmitted ? 'YES' : 'NO'}
                        </Badge>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col xs={12} md={6} className="mb-3 mb-sm-0">
                <Table borderless className="fs--1 fw-medium mb-0">
                  <tbody>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        Email:
                      </td>
                      <td className="p-1">
                        <Link to={`mailto:${data.email}`}>{data.email}</Link>
                      </td>
                    </tr>

                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        payoutsEnabled:
                      </td>
                      <td className="p-1">
                        <Badge bg="200" className="text-secondary fs--2">
                          {data.payoutsEnabled ? 'YES' : 'NO'}
                        </Badge>
                      </td>
                    </tr>

                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        stripeAccountId:
                      </td>
                      <td className="p-1">
                        {data.stripeAccountId ?? <UnknownBadge />}
                      </td>
                    </tr>

                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        Stipe Link:
                      </td>
                      <td className="p-1">
                        {data.stripeAccountId ? (
                          <a
                            target="_blank"
                            href={`https://connect.stripe.com/internal_express_login/${data.stripeAccountId}`}
                          >
                            {data.stripeAccountId}
                          </a>
                        ) : (
                          <UnknownBadge />
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        supportEmail:
                      </td>
                      <td className="p-1">
                        {data.supportEmail ? (
                          <Link to={`mailto:${data.supportEmail}`}>
                            {data.supportEmail}
                          </Link>
                        ) : (
                          <UnknownBadge />
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        supportPhone:
                      </td>
                      <td className="p-1">
                        {data.supportPhone ? (
                          <Link to={`tel:${data.supportPhone}`}>
                            {data.supportPhone}
                          </Link>
                        ) : (
                          <UnknownBadge />
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        taxId:
                      </td>
                      <td className="p-1">{data.taxId}</td>
                    </tr>

                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        vatId:
                      </td>
                      <td className="p-1 text-600">{data.vatId}</td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        createdAt:
                      </td>
                      <td className="p-1">
                        {DateTime.fromISO(data?.createdAt).toFormat(
                          "dd LLL yyyy 'at' HH:mm"
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="p-1 fw-bold" style={{ width: '35%' }}>
                        updatedAt:
                      </td>
                      <td className="p-1">
                        {DateTime.fromISO(data?.updatedAt).toFormat(
                          "dd LLL yyyy 'at' HH:mm"
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CompanyDetailsCard;
