import React, { ElementRef, FC, useRef } from 'react';
import ErrorComponent from '@/components/common/ErrorComponent';
import Flex from '@/components/common/Flex';
import Loader from '@/components/common/Loader';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Col, Row } from 'react-bootstrap';
import { selectCurrentUserConfig } from '@/services/slices/authSlice';
import { useSelector } from 'react-redux';
import { useGetDriverQuery } from '@/services/drivers';
import { useGetCategoriesQuery } from '@/services/categories';
import ICategory from '@/interfaces/Category';
import UpdateDriverOfferModal from '@/components/modals/driver/offers/UpdateDriverOfferModal';
import IconButton from '@/components/common/IconButton';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

type DriverOffersProps = {
  id: string;
};

export const DriverOffersCard: FC<DriverOffersProps> = ({ id }) => {
  const { data, isLoading, error } = useGetDriverQuery(id);
  const {
    data: dataOffers,
    isLoading: isLoadingOffers,
    error: errorOffers,
  } = useGetCategoriesQuery({});

  const userConfig = useSelector(selectCurrentUserConfig);

  const UpdateDriverOfferModalModalRef =
    useRef<ElementRef<typeof UpdateDriverOfferModal>>(null);
  if (isLoading || isLoadingOffers) {
    return <Loader />;
  } else if (error) {
    return <ErrorComponent error={error} />;
  } else if (errorOffers) {
    return <ErrorComponent error={errorOffers} />;
  }

  const driverOffers =
    data?.defaultVehicle?.offers.map((offer: ICategory) => offer.id) || [];

  return (
    <>
      {userConfig.isAdminOrSuperAdmin && (
        <UpdateDriverOfferModal
          idDriver={id}
          ref={UpdateDriverOfferModalModalRef}
        />
      )}
      <Card>
        <Card.Header className="mt-0 mb-0 pb-0">
          <Flex className="justify-content-between">
            <h1 className="fs-1 fw-bold mb-0 pb-0">Offers</h1>
            <IconButton
              onClick={() => {
                UpdateDriverOfferModalModalRef.current?.showOrHiddenModal({
                  offers: dataOffers?.data ?? [],
                });
              }}
              variant="nolina-default"
              size="sm"
              icon={faPlus}
              transform="shrink-2"
              iconAlign="middle"
              className="me-2"
            >
              <span className="d-none d-xl-inline-block ms-1">
                Attribuer une offer
              </span>
            </IconButton>
          </Flex>
        </Card.Header>

        <Card.Body>
          <Row xs={2} md={2} className="px-3">
            {dataOffers?.data.map((offer: ICategory) => (
              <Col
                key={offer.id}
                xs={12}
                md={6}
                className="offset-mb-2 border border-200"
              >
                <Row className=" rounded-3 py-2">
                  <Col xs={6} md={8}>
                    <span className="text-bold fs--1">{offer.name}:</span>
                  </Col>
                  <Col className="px-3 justify-content-end align-items-center d-flex">
                    {driverOffers.includes(offer.id) && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        size="1x"
                        color="green"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
