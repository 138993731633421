import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import ImageOverview from '@/components/common/ImageOverview';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import UnknownBadge from '@/components/common/UnknownBadge';
import { GOOGLEMAPS_COORD_LINK, GOOGLEMAPSWEBLINK } from '@/constants/places';
import {
  getBlockedBy_BasedOnStatus,
  getColorFromStatus,
  getReelNameFromDriverExperienceYears,
  getStatusName,
} from '@/helpers/DriverHelpers';
import { ICardDetailsProps } from '@/interfaces/Card';
import Driver from '@/interfaces/Driver';
import { useGetDriverQuery } from '@/services/drivers';
import { selectCurrentUser } from '@/services/slices/authSlice';
import { DateTime } from 'luxon';
import { FC, useEffect } from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import { getColorFromCompanyStatus } from '@/helpers/CompanyHelper';

const DriverDetailsCard: FC<ICardDetailsProps<Driver>> = ({ id, callBack }) => {
  const user = useSelector(selectCurrentUser);
  const { data, isLoading, isError, error } = useGetDriverQuery(id);

  useEffect(() => {
    data && callBack?.(data);
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  } else if (!data) {
    return (
      <ErrorComponent error={{ status: 404, data: {} }} absolute returnButton />
    );
  }

  return (
    <Card className="font-sans-serif ">
      <Card.Body>
        <Row className="gx-3 gy-3">
          <Col xl={4} lg={6} className="mb-3 mb-sm-0">
            <div className="pb-2 border-bottom d-flex justify-content-between">
              <h6
                className="h-2 fs-1 fw-bold text-start "
                style={{ paddingLeft: 2 }}
              >
                Personal infos
              </h6>
              <Link to={`/users/${data.userId}/edit`}>
                <FontAwesomeIcon icon={faEdit} />
              </Link>
            </div>

            <Col xs={14} md={6} sm={6} xl={6} lg={4} className="mb-3 mb-sm-0">
              <Table borderless className="fs--1 fw-medium mb-0">
                <tbody>
                  {data.user.avatar && (
                    <tr>
                      <td className="p-1" style={{ width: '35%' }}>
                        <strong>Avatar:</strong>
                      </td>
                      <td className="p-1 text-600">
                        <ImageOverview images={[data.user.avatar.link]}>
                          {setImgIndex => (
                            <Avatar
                              size="xl"
                              src={data.user.avatar.link}
                              name={data.user.fullname}
                              onClick={() => setImgIndex(0)}
                            />
                          )}
                        </ImageOverview>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      <strong>Firstname:</strong>
                    </td>
                    <td className="p-1 text-600">
                      {data.user.firstname}
                      {data.user.id === user?.id && <b> (moi)</b>}
                    </td>
                  </tr>

                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      <strong>Lastname:</strong>
                    </td>
                    <td className="p-1 text-600">{data.user.lastname}</td>
                  </tr>
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      <strong>Male/Female:</strong>
                    </td>
                    <td className="p-1 text-600">
                      {data.user?.gender ?? <UnknownBadge />}
                      {data.user.id === user?.id && <b> (moi)</b>}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      <strong>Last Online:</strong>
                    </td>
                    <td className="p-1 text-600">
                      {data?.user.lastLoginAt ? (
                        DateTime.fromISO(data.user.lastLoginAt).toRelative()
                      ) : (
                        <UnknownBadge />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      <strong>Joined:</strong>
                    </td>
                    <td className="p-1 text-600">
                      {DateTime.fromISO(data?.createdAt).toFormat(
                        "dd LLL yyyy 'at' HH:mm"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      Email :
                    </td>
                    <td className="p-1">
                      <Link
                        to={`mailto:${data.user.email}`}
                        className="text-600"
                      >
                        {data.user.email}
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      Mobile No :
                    </td>
                    <td className="p-1">
                      <a
                        href={`https://wa.me/${data.user.phone.replace(
                          /[^0-9]/g,
                          ''
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: '#007bff' }}
                        className="text-decoration-none"
                      >
                        {data.user.phone}
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      <strong>Status:</strong>
                    </td>
                    <td className="p-1">
                      <SoftBadge
                        bg={getColorFromStatus(data.blockedProfile?.status)}
                        className={'text-xl-center'}
                      >
                        {getStatusName(data.blockedProfile?.status)}
                      </SoftBadge>
                    </td>
                  </tr>
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      <strong>Tier:</strong>
                    </td>
                    <td className="p-1">{data.tier}</td>
                  </tr>
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      <strong>Referral Code:</strong>
                    </td>
                    <td className="p-1">{data?.referralCode}</td>
                  </tr>
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      <strong>User Update Status:</strong>
                    </td>
                    <td className="p-1">
                      {data.blockedProfile ? (
                        getBlockedBy_BasedOnStatus(data)!.fullname
                      ) : (
                        <UnknownBadge />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      <strong>Update Status Date:</strong>
                    </td>
                    <td className="p-1">
                      {data.blockedProfile ? (
                        DateTime.fromISO(
                          data.blockedProfile.updatedAt
                        ).toLocaleString({
                          dateStyle: 'medium',
                          timeStyle: 'short',
                        })
                      ) : (
                        <UnknownBadge />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className="p-1" style={{ width: '35%' }}>
                      <strong>Branding:</strong>
                    </td>
                    <td className="p-1">
                    {data.branding
                      ? DateTime.fromISO(data.branding).toFormat('dd/MM/yyyy')
                      : 'N/A'}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Col>
          <Col xl={4} lg={6} className="mb-3 mb-sm-0">
            <div className="pb-2 border-bottom d-flex justify-content-between">
              <h6
                className="h-2 fs-1 fw-bold text-start "
                style={{ paddingLeft: 2 }}
              >
                Company Info
              </h6>
              <Link to={`/companies/${data.company?.id}/edit`}>
                <FontAwesomeIcon icon={faEdit} />
              </Link>
            </div>
            <Table borderless className="fs--1 fw-medium mb-0">
              <tbody>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Company Name: </strong>
                  </td>
                  <td className="p-1">
                    <Link to={`/companies/${data.company?.id}`}>
                      {data.company?.name}
                    </Link>
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Company Address:</strong>
                  </td>
                  <td className="p-1">
                    {' '}
                    {data.company?.address.address ? (
                      <Link
                        to={`${GOOGLEMAPSWEBLINK}${data.company?.address.placeId}`}
                      >
                        {data.company?.address.address}
                      </Link>
                    ) : (
                      <UnknownBadge />
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Stripe Account:</strong>
                  </td>
                  <td className="p-1">
                    {' '}
                    {data.company?.stripeAccountId ? (
                      <Link
                        to={`https://connect.stripe.com/connect/accounts/${data.company?.stripeAccountId}/activity`}
                      >
                        {data.company?.stripeAccountId}
                      </Link>
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Postal code:</strong>
                  </td>
                  <td className="p-1">{data.company?.address.postalCode}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>City:</strong>
                  </td>
                  <td className="p-1">{data.company?.address.city}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '40%' }}>
                    <strong>Country:</strong>
                  </td>
                  <td className="p-1">{data.company?.address.country}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Numéro de SIREN:</strong>
                  </td>
                  <td className="p-1">{data.company?.taxId}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Numéro de TVA:</strong>
                  </td>
                  <td className="p-1">{data.company?.vatId}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>NIF:</strong>
                  </td>
                  <td className="p-1">
                    <UnknownBadge />
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Rattachement:</strong>
                  </td>
                  <td className="p-1">
                    {data.isCompanyRattachement ? (
                      <SoftBadge bg="success">Yes</SoftBadge>
                    ) : (
                      <SoftBadge bg="danger">Non</SoftBadge>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>TVA:</strong>
                  </td>
                  <td className="p-1">
                    {data.tvaRedevable ? (
                      <SoftBadge bg="success">Yes</SoftBadge>
                    ) : (
                      <SoftBadge bg="danger">Non</SoftBadge>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>IBAN:</strong>
                  </td>
                  <td
                    className="p-1"
                    style={{ inlineSize: 20, overflowWrap: 'anywhere' }}
                  >
                    {data.company?.bankAccountNumber ?? <UnknownBadge />}
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Stripe status:</strong>
                  </td>
                  <td className="p-1">
                    <SoftBadge
                      bg={getColorFromCompanyStatus(
                        data?.company?.status ?? 'inactive'
                      )}
                      className={'text-xl-center'}
                    >
                      {data.company?.status}
                    </SoftBadge>
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Stripe link:</strong>
                  </td>
                  <td className="p-1">
                    {data?.company?.stripeVerifyLink ?? <UnknownBadge />}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col xl={4} lg={6} className="mb-3 mb-sm-0">
            <h6
              className="h-2 fs-1 fw-bold text-start pb-3 border-bottom "
              style={{ paddingLeft: 2 }}
            >
              Key Stats
            </h6>
            <Table borderless className="fs--1 fw-medium mb-0">
              <tbody>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Driver exp:</strong>
                  </td>
                  <td className="p-1">
                    {getReelNameFromDriverExperienceYears(
                      data.driverExperienceYears
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Day & Night :</strong>
                  </td>
                  <td className="p-1">{data.primaryShift}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Navigation preference:</strong>
                  </td>
                  <td className="p-1">{data.preferredNavigationApp}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong># Rides Completed:</strong>
                  </td>
                  <td className="p-1">
                    {data.averageRating?.toString().split('.')[0]}
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '40%' }}>
                    <strong>€ Earned :</strong>
                  </td>
                  <td className="p-1">{`${data.totalEarned}€`}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Rating Driver:</strong>
                  </td>
                  <td className="p-1">{data.averageRating}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Accept rate:</strong>
                  </td>
                  <td className="p-1">{`${data.acceptedRate ?? 0}%`}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Cancel rate:</strong>
                  </td>
                  <td className="p-1">{`${data.cancelledRate ?? 0}%`}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong># Driver Referrals :</strong>
                  </td>
                  <td className="p-1">{data.referralsCount}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong># User Referrals :</strong>
                  </td>
                  <td className="p-1">{data.user.referredUsersCount ?? 0}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong># Gov. Votes :</strong>
                  </td>
                  <td className="p-1">{data.user.governanceVotesCount}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Referred</strong>
                  </td>
                  <td className="p-1">{!!data.referral ? 'YES' : 'NO'}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Last location:</strong>
                  </td>
                  <td className="p-1">
                    {data.longitude && data.latitude ? (
                      <Link
                        to={GOOGLEMAPS_COORD_LINK(
                          data.latitude,
                          data.longitude
                        )}
                      >
                        Location google
                      </Link>
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Last location time:</strong>
                  </td>
                  <td className="p-1">
                    {data.longitude && data.latitude ? (
                      <span>
                        {dayjs(data.lastLocationAt).format('DD/MM/YYYY HH:mm')}
                      </span>
                    ) : (
                      'N/A'
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Last location Source:</strong>
                  </td>
                  <td className="p-1">{data.locationSource}</td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>Location Permission:</strong>
                  </td>
                  <td className="p-1">
                    {data.locationGranted ? (
                      <SoftBadge bg="success">Granted</SoftBadge>
                    ) : (
                      <SoftBadge bg="secondary">Not allowed</SoftBadge>
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>App version:</strong>
                  </td>
                  <td className="p-1">
                    {data?.appVersion ?? <UnknownBadge />}
                  </td>
                </tr>
                <tr>
                  <td className="p-1" style={{ width: '35%' }}>
                    <strong>App OS:</strong>
                  </td>
                  <td className="p-1">{data?.appOS ?? <UnknownBadge />}</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default DriverDetailsCard;
