import Avatar from '@/components/common/Avatar';
import ErrorComponent from '@/components/common/ErrorComponent';
import Flex from '@/components/common/Flex';
import ImageOverview from '@/components/common/ImageOverview';
import Loader from '@/components/common/Loader';
import SoftBadge from '@/components/common/SoftBadge';
import UnknownBadge from '@/components/common/UnknownBadge';
import Table from '@/components/common/table/Table';
import AllDriversHeader from '@/components/table-headers/drivers/AllDriversHeader';
import { GOOGLEMAPS_COORD_LINK } from '@/constants/places';
import { getColorFromCompanyStatus } from '@/helpers/CompanyHelper';
import {
  getColorFromService,
  getColorFromStatus,
  getNameFromService,
  getStatusName,
} from '@/helpers/DriverHelpers';
import { getGenderAlias } from '@/helpers/UserHelpers';
import { getRate } from '@/helpers/utils';
import Driver from '@/interfaces/Driver';
import { IFilters } from '@/interfaces/Fetch';
import { useGetDriversQuery } from '@/services/drivers';
import { selectCurrentUser } from '@/services/slices/authSlice';
import { faEdit, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, TableState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { DateTime } from 'luxon';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const DriversView: FC = () => {
  const [driversData, setDriversData] = useState<Driver[]>([]);

  const user = useSelector(selectCurrentUser);
  const [state, setState] = useState<IDriversFilter>({
    pagination: {
      pageIndex: 0,
      pageSize: 50,
    },
    globalFilter: '',
    sorting: [
      {
        id: 'createdAt',
        desc: true,
      },
    ],
  });

  const { data, isLoading, isError, error, isFetching } = useGetDriversQuery({
    ...state.pagination,
    query: state.globalFilter,
    branding: state.branding,
    commission: state.commission,
    completedRides: state.completedRides,
    acceptanceRate: state.acceptanceRate,
    ...(state.sorting?.[0] && {
      sortBy: state.sorting[0].id,
      sortDirection: state.sorting[0].desc ? 'desc' : 'asc',
    }),
  });

  const totalCompanies = data?.meta?.total;

  const truncate = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? `${text.substring(0, maxLength)}...`
      : text;
  };

  const columns: ColumnDef<Driver>[] = useMemo(
    () => [
      {
        header: 'Actions',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ id }) => (
          <>
            <Link to={`/drivers/${id}`} className="btn btn-primary btn-sm">
              <FontAwesomeIcon icon={faEye} />
            </Link>
            <Link
              to={`/drivers/${id}/edit`}
              className="btn btn-info btn-sm ms-2"
            >
              <FontAwesomeIcon icon={faEdit} />
            </Link>
          </>
        ),
        id: 'actions',
        cell: info => info.getValue(),
      },
      {
        header: '#',
        id: 'number',
        cell: info => {
          if (totalCompanies === undefined) {
            return 'Loading...';
          }
          const pageIndex = info.table.getState().pagination.pageIndex;
          const pageSize = info.table.getState().pagination.pageSize;
          const rowIndex = info.row.index;
          const recordNumber =
            totalCompanies - (pageIndex * pageSize + rowIndex);
          return recordNumber;
        },
      },

      {
        header: 'Registration\nDate & Time',
        footer: props => props.column.id,
        accessorFn: ({ createdAt }) => (
          <>
            {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy')}
            <p className="mb-0 text-500">
              {DateTime.fromISO(createdAt).toFormat('HH:mm')}
            </p>
          </>
        ),
        id: 'createdAt',
        cell: info => info.getValue(),
      },
      {
        header: 'First Name\nLast Name',
        footer: props => props.column.id,
        enableSorting: false,
        accessorFn: ({ user: { lastname, firstname, avatar, gender }, id }) => (
          <Link to={`/drivers/${id}`}>
            <Flex alignItems="center">
              {avatar && (
                <Avatar
                  src={avatar.link}
                  alt={`${firstname} Avatar`}
                  className="me-2"
                  size="2xl"
                />
              )}
              <p className="mb-0">
                {gender && <span>{getGenderAlias(gender)}</span>}{' '}
                <span>{firstname}</span>
                <br />
                <span>{lastname}</span>
              </p>
              {user?.id === id && <b className="ms-2">(moi)</b>}
            </Flex>
          </Link>
        ),
        id: 'fullname',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: 'Email\nPhone Number',
        footer: props => props.column.id,
        accessorFn: ({ user: { email, phone } }) => (
          <>
            {email}
            <p className="mb-0 text-500">
              <a
                href={`https://wa.me/${phone.replace(/[^0-9]/g, '')}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: '#007bff' }}
                className="text-decoration-none"
              >
                {phone}
              </a>
            </p>
          </>
        ),
        id: 'email',
        cell: info => info.getValue(),
      },
      {
        header: 'Com rate',
        footer: props => props.column.id,
        accessorFn: ({ commissionRate }) => (
          <div>{commissionRate != null ? `${commissionRate}%` : '10%'}</div>
        ),
        id: 'commissionRate',
        cell: info => info.getValue(),
      },
      {
        header: 'Com Start Date\nCom End Date',
        footer: props => props.column.id,
        accessorFn: ({ commissionStartDate, commissionEndDate }) => (
          <>
            {commissionStartDate
              ? DateTime.fromISO(commissionStartDate).toFormat('dd/MM/yyyy')
              : 'N/A'}
            <p className="mb-0 text-500">
              {commissionEndDate
                ? DateTime.fromISO(commissionEndDate).toFormat('dd/MM/yyyy')
                : 'N/A'}
            </p>
          </>
        ),
        id: 'commissionStartEndDate',
        cell: info => info.getValue(),
      },
      {
        header: 'Company',
        enableSorting: false,
        footer: (props: any) => props.column.id,
        accessorFn: ({ company }) => {
          const stripeValue = company?.stripeAccountId ? (
            <Link
              to={`https://connect.stripe.com/connect/accounts/${company?.stripeAccountId}/activity`}
            >
              {company?.stripeAccountId}
            </Link>
          ) : (
            'N/A'
          );

          return (
            <Flex direction="column">
              <div>{company?.name ?? 'N/A'}</div>
              <div>{stripeValue}</div>
            </Flex>
          );
        },
        id: 'companyName',
        cell: info => info.getValue(),
      },
      {
        header: 'Driver Status',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ blockedProfile }) => (
          <div>
            <SoftBadge
              bg={getColorFromStatus(blockedProfile?.status)}
              className={'my-2 text-xl-center'}
            >
              {getStatusName(blockedProfile?.status)}
            </SoftBadge>
          </div>
        ),
        id: 'blockedProfile.status',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: 'Stripe Status',
        footer: props => props.column.id,
        accessorFn: ({ company }) => (
          <SoftBadge
            bg={getColorFromCompanyStatus(company?.status ?? 'inactive')}
            className="text-xl-center"
          >
            {company?.status ?? 'inactive'}
          </SoftBadge>
        ),
        id: 'stripeStatus',
        cell: info => info.getValue(),
      },
      {
        header: 'Session Status',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ services }) => {
          if (services && services.length > 0) {
            const serviceStatus = services[0].status;
            return (
              <div>
                <SoftBadge
                  bg={getColorFromService(serviceStatus)}
                  className={'my-2 text-xl-center'}
                >
                  {getNameFromService(serviceStatus)}
                </SoftBadge>
              </div>
            );
          }
          return (
            <div>
              <SoftBadge bg="danger" className={'my-2 text-xl-center'}>
                None
              </SoftBadge>
            </div>
          );
        },
        id: 'services.status',
        cell: info => info.getValue(),
      },
      {
        header: 'Rattachement',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ isCompanyRattachement }) => {
          return (
            <div>
              {isCompanyRattachement ? (
                <SoftBadge bg="success" className="my-2 text-xl-center">
                  Yes
                </SoftBadge>
              ) : (
                <SoftBadge bg="danger" className="my-2 text-xl-center">
                  Non
                </SoftBadge>
              )}
            </div>
          );
        },
        id: 'isCompanyRattachement',
        cell: info => info.getValue(),
      },
      {
        header: 'Last Session',
        footer: props => props.column.id,
        accessorFn: ({ services }) => {
          if (services && services.length > 0) {
            return (
              <>
                {DateTime.fromISO(services[0].createdAt).toFormat('dd/MM/yyyy')}
                <p className="mb-0 text-500">
                  {DateTime.fromISO(services[0].createdAt).toFormat('HH:mm')}
                </p>
              </>
            );
          }
          return 'N/A';
        },
        id: 'services.createdAt',
        cell: info => info.getValue(),
      },
      {
        header: 'Last Location',
        footer: props => props.column.id,
        accessorFn: ({ longitude, latitude }) => {
          if (!longitude || !latitude) return 'N/A';
          return (
            <Link to={GOOGLEMAPS_COORD_LINK(latitude, longitude)}>
              localisation google
            </Link>
          );
        },
        id: 'services.lastLocation',
        cell: info => info.getValue(),
      },
      {
        header: 'Last Location time',
        footer: props => props.column.id,
        accessorFn: ({ longitude, latitude, updatedAt }) => {
          if (!longitude || !latitude) return 'N/A';
          return <div>{dayjs(updatedAt).format('DD/MM/YYYY HH:mm')}</div>;
        },
        id: 'services.lastLocationTime',
        cell: info => info.getValue(),
      },
      {
        header: 'App version',
        footer: props => props.column.id,
        accessorFn: ({ appVersion }) => {
          return <div>{appVersion}</div>;
        },
        id: 'services.appVersion',
        cell: info => info.getValue(),
      },
      {
        header: 'App OS',
        footer: props => props.column.id,
        accessorFn: ({ appOS }) => {
          return <div>{truncate(appOS, 7)}</div>;
        },
        id: 'services.appOs',
        cell: info => info.getValue(),
        meta: {
          headerProps: {
            style: { minWidth: '5rem', maxWidth: '5rem' },
          },
          cellProps: {
            style: { minWidth: '5rem', maxWidth: '5rem' },
            className: 'text-wrap',
          },
        },
      },
      {
        header: 'Last call',
        footer: props => props.column.id,
        accessorFn: ({ lastCallDate }) => {
          if (!lastCallDate) return 'N/A';
          return dayjs(lastCallDate).format('DD/MM/YYYY');
        },
      },
      {
        header: '# Rides Completed\n# Rides Accepted',
        footer: props => props.column.id,
        accessorFn: ({ completedRidesCount, acceptedRidesCount }) => (
          <>
            {completedRidesCount}
            <p className="mb-0 text-500">{acceptedRidesCount}</p>
          </>
        ),
        id: 'completedRidesCount',
        cell: info => info.getValue(),
      },
      {
        header: 'Accept. Rate\nCancel. Rate',
        footer: props => props.column.id,
        accessorFn: ({
          acceptedRidesCount,
          cancelledRidesCount,
          totalRidesCount,
        }) => (
          <>
            {getRate(acceptedRidesCount, totalRidesCount) === '0%' ? (
              <SoftBadge bg="warning" className="my-2 text-xl-center">
                None
              </SoftBadge>
            ) : (
              getRate(acceptedRidesCount, totalRidesCount)
            )}
            <p className="mb-0 text-500">
              {getRate(cancelledRidesCount, acceptedRidesCount) === '0%' ? (
                <SoftBadge bg="warning" className="my-2 text-xl-center">
                  None
                </SoftBadge>
              ) : (
                getRate(cancelledRidesCount, acceptedRidesCount)
              )}
            </p>
          </>
        ),
        id: 'acceptanceRate',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: '# Ref Users',
        footer: props => props.column.id,
        accessorFn: ({ referralsCount }) => referralsCount,
        id: 'referredUsersCount_',
        cell: info => info.getValue(),
      },
      {
        header: 'Car Brand\nCar Plate',
        enableSorting: false,
        footer: props => props.column.id,
        accessorFn: ({ defaultVehicle, licensePlate }) =>
          defaultVehicle ? (
            <>
              {`${defaultVehicle.vehicle.brand} - ${defaultVehicle.vehicle.model}`}
              <p className="mb-0 text-500">{licensePlate}</p>
            </>
          ) : (
            <UnknownBadge />
          ),
        id: 'defaultVehicle',
        cell: info => info.getValue(),
      },
      {
        header: 'Branding',
        footer: props => props.column.id,
        accessorFn: ({ branding }) => {
          return (
            <div>
              {branding ? (
                <>
                  <SoftBadge bg="success" className="my-2 text-xl-center">
                    Yes
                  </SoftBadge>
                  <div>{DateTime.fromISO(branding).toFormat('dd/MM/yy')}</div>
                </>
              ) : (
                <SoftBadge bg="danger" className="my-2 text-xl-center">
                  No
                </SoftBadge>
              )}
            </div>
          );
        },
        id: 'branding',
        cell: info => info.getValue(),
      },

      {
        header: 'Last Ride ID',
        footer: props => props.column.id,
        accessorFn: ({ lastCompletedRideRequest }) =>
          lastCompletedRideRequest ? (
            lastCompletedRideRequest.id
          ) : (
            <UnknownBadge />
          ),
        id: 'lastCompletedRideRequest.id',
        cell: info => info.getValue(),
      },
      {
        header: 'Last Ride Date\n& Time',
        footer: props => props.column.id,
        accessorFn: ({ lastCompletedRideRequest }) =>
          lastCompletedRideRequest ? (
            <>
              {DateTime.fromISO(lastCompletedRideRequest.createdAt).toFormat(
                'dd/MM/yyyy'
              )}
              <p className="mb-0 text-500">
                {DateTime.fromISO(lastCompletedRideRequest.createdAt).toFormat(
                  'HH:mm'
                )}
              </p>
            </>
          ) : (
            <UnknownBadge />
          ),
        id: 'lastCompletedRideRequest.createdAt',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: 'Total € Earned',
        footer: props => props.column.id,
        accessorFn: ({ totalEarned }) =>
          totalEarned ? `${totalEarned}€` : <UnknownBadge />,
        id: 'totalEarned',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: 'Av. Rating',
        footer: props => props.column.id,
        accessorFn: ({ averageRating }) =>
          averageRating ? averageRating : <UnknownBadge />,
        id: 'averageRating',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: '# Points',
        footer: props => props.column.id,
        accessorFn: ({ points }) => points,
        id: 'points',
        cell: info => info.getValue(),
      },
      {
        enableSorting: false,
        header: 'Referral Code',
        footer: props => props.column.id,
        accessorFn: ({ referralCode }) => (
          <p style={{ fontWeight: 'bold' }}>{referralCode}</p>
        ),
        id: 'referralCode',
        cell: info => info.getValue(),
      },
      {
        header: 'Driver ID',
        footer: props => props.column.id,
        accessorFn: ({ id }) => (
          <Link to={`/drivers/${id}`}>{truncate(id, 10)}</Link>
        ),
        id: 'id',
        cell: info => info.getValue(),
      },
    ],
    [totalCompanies, state.pagination?.pageIndex, state.pagination?.pageSize]
  );

  useEffect(() => {
    if (data) {
      setDriversData(data.data || []);
    }
  }, [data]);

  if (isLoading) {
    return <Loader absolute />;
  } else if (isError) {
    return <ErrorComponent error={error} absolute returnButton />;
  }

  return (
    <Table
      HeaderComponent={AllDriversHeader}
      columns={columns}
      paddingRows="md"
      state={state}
      tableName="drivers"
      loading={isFetching || isLoading}
      onStateChange={setState}
      data={data?.data}
      meta={data?.meta}
    />
  );
};

export default DriversView;

interface IDriversFilter extends Partial<TableState>, IFilters {
  commission?: number;
  completedRides?: string;
  acceptanceRate?: string;
  branding?: string;
}
